import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import EntityController from "@/entity/_controller/EntityController";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {StorageScope} from "@/_model/app.constants";
import fileManager, {FileResponse} from "@/_controller/FileManager";
import {
    IDataItemDefinitionDto,
    IDataProviderBodyDto,
    IDataSchemaDto,
    IDataURIDto, IXlsSheet
} from "@/data_tool/_model/data_tool.dto";
import {DataResourceScope, PropertyType} from "@/data_tool/_model/data_tool.constants";
import DataUtil from "@/data_tool/_controller/DataUtil";
import AppUserModel from "@/project/user/_model/AppUserModel";
import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
import DataSchemaUtil from "@/data_tool/_controller/DataSchemaUtil";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import DataItemListModel from "@/data_tool/data_item/_model/DataItemListModel";
import FileSaver from "file-saver";
import ExcelJS, {Column, Row, Workbook, Worksheet} from "exceljs";
import languageManager from "@/__libs/language_manager/LanguageManager";
import FileUtil from "@/__libs/utility/FileUtil";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IUpdateResultDto} from "@/entity/_model/entity.dto";

//contains all (static) controller methods that can be initiated on a single dtp
class DataProviderController extends EntityController
{

    //---------------------------------
    // Properties
    //---------------------------------



    //---------------------------------
    // Controller Methods
    //---------------------------------


    // fetch the schema and its dependent schemas and build the actual provider
    public async fetchDataProviderDependencies(p_dataProvider:DataProviderModel):Promise<boolean>
    {
        const schema:IDataSchemaDto | null = await this.fetchDataSchema(p_dataProvider.dataSchemaURI, p_dataProvider);
        if (schema)
        {
            p_dataProvider.build(schema);
            return true;
        }
        return false;
    }

    //get the root schema and all dependent schema's, the p_dataProvider is the model the references to all these schema's need to be kept (in it's dataSchemaDict)
    public async fetchDataSchema(p_dataSchemaURI:string, p_dataProvider:DataProviderModel):Promise<IDataSchemaDto | null>
    {
        let schema:IDataSchemaDto | null = p_dataProvider.dataSchemaDict.getItem(p_dataSchemaURI);
        if (!schema)
        {
            const dataURIDto:IDataURIDto = DataUtil.resolveURI(p_dataSchemaURI);
            const scope:StorageScope = dataURIDto.scope === DataResourceScope.GLOBAL ? StorageScope.GLOBAL : StorageScope.PROJECT;

            //when in development mode, the schemas are loaded from the dev storage url (warning: CORS should be enabled on your localhost for this to work )
            let filePath:string = "";
            const isLocalhost:boolean = (process.env.VUE_APP_DEV_STORAGE_URL && process.env.NODE_ENV === "development") as boolean;
            if (isLocalhost)
            {
                filePath += dataURIDto.scope === DataResourceScope.GLOBAL ? "_global/" : AppUserModel.getInstance().project.identifier + "/";
            }
            filePath += `schemas/${dataURIDto.path}.json`;

            const fileResponse:FileResponse = await fileManager.fetchFileFromCdn(filePath, scope, undefined, isLocalhost);
            if (fileResponse.hasSucceeded)
            {
                const schema:IDataSchemaDto = fileResponse.result as IDataSchemaDto;
                DataSchemaUtil.validateSchema(schema);
                p_dataProvider.dataSchemaDict.setItem(schema, p_dataSchemaURI);

                const dependentSchemaURIs = DataSchemaUtil.resolveDependentSchemas(schema);
                for (let i = 0; i < dependentSchemaURIs.length; i++)
                {
                    //recursively get all dependent schema's and keep them in the p_dataProvider's dictionary
                    await this.fetchDataSchema(dependentSchemaURIs[i], p_dataProvider);
                }
                return schema;
            }
        }
        else
        {
            return schema;
        }

        return null;
    }



    //fetch a data item list to be used in a SELECT_LIST or ITEM_DICTIONARY
    //p_property is the model the list needs to be kept in
    public async fetchDataItemList(p_dataProviderURI:string, p_property:PropertyModel):Promise<boolean>
    {
        const uri:IDataURIDto = DataUtil.resolveURI(p_dataProviderURI);
        const scope:StorageScope = uri.scope === DataResourceScope.GLOBAL ? StorageScope.GLOBAL : StorageScope.PROJECT;
        const dtpID:string = uri.path;
        const itemDefinition:IDataItemDefinitionDto = p_property.propertyDefinition.type === PropertyType.ITEM_DICTIONARY ? p_property.itemKeyDefinition! : p_property.itemDefinition!;
        const itemIdentifier:string = itemDefinition.identifier;

        const key:string = `${dtpID}#${itemIdentifier}`;


        if (!p_property.dataProvider.dataItemListsDict.keyExists(key))
        {

            const response:ApiResponse<IDataProviderBodyDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/data-providers/${dtpID}`);

            if (response.hasSucceeded)
            {
                const dtpBody:IDataProviderBodyDto = response.result as IDataProviderBodyDto;

                //parse the dataprovider def into a listmodel
                const dataItemList:DataItemListModel = new DataItemListModel(itemDefinition, dtpBody.data[itemIdentifier], p_property.dataProvider, null);
                //and set the dictionary
                p_property.dataProvider.dataItemListsDict.setItem(dataItemList, key);
                p_property.dataItemList = dataItemList;

                return true;
            }
        }
        else
        {
            p_property.dataItemList = p_property.dataProvider.dataItemListsDict.getItem(key);
            return true;
        }
        return false;
    }


    //fetch a dataprovider to be used in a DATAPROVIDER_LINK
    //p_property is the model the dtp needs to be kept in
    public async fetchLinkedDataProvider(p_dataProviderURI:string, p_property:PropertyModel):Promise<boolean>
    {
        const uri:IDataURIDto = DataUtil.resolveURI(p_dataProviderURI);
        const scope:StorageScope = uri.scope === DataResourceScope.GLOBAL ? StorageScope.GLOBAL : StorageScope.PROJECT;
        const dtpID:string = uri.path;

        const response:ApiResponse<IDataProviderBodyDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/data-providers/${dtpID}`);

        if (response.hasSucceeded)
        {
            const dtpBody:IDataProviderBodyDto = response.result as IDataProviderBodyDto;

            p_property.linkedDataProviderBodyDto = dtpBody;

            return true;
        }

        return false;
    }


    public deleteDataItem(p_dataItem:DataItemModel)
    {
        if (p_dataItem.__parent instanceof DataItemListModel)
        {
            const dataList:DataItemListModel = p_dataItem.__parent as DataItemListModel;
            dataList.deleteItem(p_dataItem);
        }
    }

    public async exportToXls(p_dataProvider:DataProviderModel)
    {
        const sheets:IXlsSheet[] = await p_dataProvider.exportToXls();

        const levelColors:string[] = ["FF000000", '007bbd', "FFff7791", 'a08e89'];

        const workbook = new Workbook();
        for (let i = 0; i < sheets.length; i++)
        {
            const sheet:IXlsSheet = sheets[i];
            const newSheet = workbook.addWorksheet(sheet.identifier);
            const columns:Partial<Column>[] = [];
            for (let j = 0; j < sheet.columns.length; j++)
            {
                const dtColumn = sheet.columns[j];
                const xlsColumn:Partial<Column> = {
                    header: dtColumn.identifier,
                    key   : dtColumn.identifier,
                    width : 12,
                    style : {font: {color: {argb: levelColors[dtColumn.level!]}}}
                };
                // if(dtColumn.level && dtColumn.level > 0)
                // {
                //     xlsColumn.outlineLevel =  dtColumn.level + 1;
                // }
                columns.push(xlsColumn);
            }
            newSheet.columns = columns;
            newSheet.addRows(sheet.rows);

            //styling header
            newSheet.getRow(1).alignment = {wrapText: true};
            newSheet.getRow(1).font = {bold: true};
            newSheet.getRow(1).border = {bottom: {style: "double"}};
            newSheet.getRow(1).fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD9D9D9'}};

            //styling rows
            newSheet.eachRow({includeEmpty: true}, function (row, rowNumber) {
                if (rowNumber > 1) //don't include the header
                {
                    row.height = 20;
                    row.alignment = {wrapText: true};
                }
            })
        }



        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: "applicationi/xlsx"});

        const dtpName:string = languageManager.getTranslationForValue<string>(p_dataProvider.name, AppUserModel.getInstance().langCode)
        FileSaver.saveAs(blob, `${dtpName}.xlsx`);

    }


    public async importFromXls(p_file:File, p_dataProvider:DataProviderModel)
    {
        let contentBuffer = await FileUtil.readFileAsync(p_file);

        const workbook = new Workbook();
        await workbook.xlsx.load(contentBuffer as any);

        const dtSheets:IXlsSheet[] = [];

        for (let i = 0; i < workbook.worksheets.length; i++)
        {

            const worksheet:Worksheet = workbook.worksheets[i];
            const dtSheet:IXlsSheet = {identifier: worksheet.name, columns: [], rows: []};

            const header:Row = worksheet.getRow(1);
            header.eachCell(function (cell, cellNumber) {
                if (cell.value)
                {
                    dtSheet.columns.push({identifier: cell.value.toString()})
                }
            });

            worksheet.eachRow({includeEmpty: true}, function (row, rowNumber) {
                if (rowNumber > 1) //don't include the columnnames
                {
                    //old "row as array values" code
                    // const dtRow:any[] = [];
                    // row.eachCell({includeEmpty: true}, function (cell, cellNumber) {
                    //     dtRow.push(cell.value)
                    // });
                    const dtRow:any = {};
                    row.eachCell(function (cell, cellNumber) {
                        dtRow[dtSheet.columns[cellNumber - 1].identifier] = cell.value;
                    });
                    dtSheet.rows.push(dtRow);
                }
            });
            dtSheets.push(dtSheet)
        }
        return p_dataProvider.importFromXls(dtSheets);
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

    //build the actual data entries from the fetched schemas and dataproviders
    private _buildDpEntries(p_dataProvider:DataProviderModel)
    {

    }



}

//Singleton export
export default new DataProviderController(ENTITY_CONFIG.DATA_PROVIDER);
