<template>
  <div class="sub-page audience-list-page">

    <AudiencesOverview></AudiencesOverview>
    <AudienceDetail></AudienceDetail>
    <AudienceSummary></AudienceSummary>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AudienceSummary from "@/audience/_view/AudienceSummary.vue";
import AudienceDetail from "@/audience/_view/AudienceDetail.vue";
import AudiencesOverview from "@/audience/_view/AudiencesOverview.vue";

@Component({
  components: {AudiencesOverview, AudienceDetail, AudienceSummary}
})
export default class AudienceListPage extends Vue {
}
</script>
