<template>
    <div class="player content-file-player">

        <PDFViewer
                v-if="contentFileIsPDF"
                :engMode="engineMode"
                :pdfURL="fileManager.getFileUrl(contentFile.fileUri)"
                @pageViewed="handlePageViewed"/>

        <iframe v-if="!contentFileIsPDF" class="player__frame" ref="playerFrame" v-on:load="_onIframeLoad()"></iframe>

        <div class="player__back" @click="_onBackButtonClick">
            <div class="player__back__background"></div>
            <i class="fas fa-chevron-left"></i>
        </div>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import AudienceModel from "@/audience/_model/AudienceModel";
    import AudienceListModel from "@/audience/_model/AudienceListModel";
    import ContentListModel from "@/content/_model/ContentListModel";
    import {EngineMessageType, EngineMode} from "@/presentation/player/_model/player.constants";
    import ContentFileModel from "@/content_file/_model/ContentFileModel";
    import {IEngineMessageDto} from "@/presentation/player/_model/player.dto";
    import audienceController from "@/audience/_controller/AudienceController";
    import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
    import {IAudienceSessionEventDto} from "@/audience/session/_model/audience_session.dto";
    import contentController from "@/content/_controller/ContentController";
    import {RoutingIdentifier} from "@/router";
    import fileManager from "@/_controller/FileManager";
    import {FileType} from '@/asset_folder/_model/asset_folder.constants';
    import PDFViewer from "../../../_view/components/PDFViewer.vue";
    import {SessionMode} from "@/audience/session/_model/audience_session.constants";
    import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
    import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
    import FileUtil from "@/__libs/utility/FileUtil";

    @Component({
        components: {PDFViewer}
    })
    export default class ContentFilePlayerPage extends Vue
    {

        @Prop() private engineMode!:EngineMode;
        @Prop() private contentFileID!:string;
        @Prop() private sessionIdentifier!:string;

        private shareMessage:string = "";

        private contentFileTypes = FileType;
        private fileManager = fileManager;

        get audience():AudienceModel | null
        {
            return AudienceListModel.getInstance().globalSelState.selected;
        }

        get contentFile():ContentFileModel | null
        {
            if (this.contentFileID)
            {
                return ContentListModel.getInstance().getEntityByID(this.contentFileID) as ContentFileModel;
            }
            return null;
        }

        get contentFileIsPDF():null | boolean
        {
            if(this.contentFile)
            {
                const fileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(this.contentFile.fileUri));
                return fileType === this.contentFileTypes.PORTABLE_DOCUMENT;
            }
            return false;
        }

        public mounted()
        {
            window.addEventListener('message', this.listenToEngine);
            window.addEventListener('beforeunload', this._beforeUnload);
            this._setPlayerUrl();
        }

        public beforeDestroy()
        {
            window.removeEventListener('message', this.listenToEngine);
            window.removeEventListener('beforeunload', this._beforeUnload);
        }

        @Watch('contentFileID', {immediate: true, deep: false})
        @Watch('audience', {immediate: true, deep: false})
        private async _setPlayerUrl()
        {
            const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;

            if (playerFrame)
            {
                playerFrame.src = fileManager.getFileUrl(this.contentFile!.fileUri);
            }

        }

        private _onIframeLoad()
        {
            const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
            // @ts-ignore;
            playerFrame.contentWindow.focus();
        }

        private async listenToEngine(p_messageEvent:MessageEvent)
        {
            //todo: check origin

            const message:IEngineMessageDto = p_messageEvent.data;
            if (message.messageType)
            {

                switch (message.messageType)
                {
                    case  EngineMessageType.ASK_PATCH_AUDIENCE:
                        audienceController.patchBody(this.audience!, message.messageValue);
                        break;

                    case  EngineMessageType.REGISTER_AUDIENCE_SESSION:
                        audienceSessionController.registerAudienceSession(message.messageValue);
                        break;

                    case  EngineMessageType.REGISTER_AUDIENCE_SESSION_EVENT:
                        (message.messageValue as IAudienceSessionEventDto).audienceID = this.audience!.ID;
                        (message.messageValue as IAudienceSessionEventDto).parentSessionIdentifier = this.sessionIdentifier;
                        audienceSessionController.registerAudienceSessionEvent(message.messageValue);
                        break;

                    case  EngineMessageType.ASK_SAVE:
                        if (this.contentFile)
                        {
                            contentController.saveBody(this.contentFile, message.messageValue);
                        }
                        break;

                    case  EngineMessageType.ASK_SHARE_WITH_AUDIENCE:
                        if (this.contentFile)
                        {
                            this.shareMessage = message.messageValue.shareMessage;
                            this.$bvModal.show('share-with-audience-box');
                        }
                        break;

                }
            }
            else
            {
                return;
            }
        }

        private _beforeUnload(e:BeforeUnloadEvent)
        {

            // e.returnValue = "Are you sure you want to leave, changes you made will not be saved.";
            // return e.returnValue;
        }

        private _onShareWithAudienceFinish()
        {
            this.$bvModal.hide('share-with-audience-box');
        }

        private async _onBackButtonClick()
        {
            contentController.stopRemotePresent();
            await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
        }

        private handlePageViewed(p_pageNumber:number, secondsViewed:number)
        {
            if(this.contentFile && this.audience && this.sessionIdentifier && this.engineMode === EngineMode.PRESENT)
            {
                audienceSessionController.createAudienceSession(this.contentFile, this.audience, SessionMode.PRESENT, ActivityType.PAGE_SESSION, p_pageNumber.toString(), secondsViewed, this.sessionIdentifier);
            }
        }

    }
</script>
