<template>
  <div class="world" id="app">

    <!-- Main pages !-->
    <transition appear name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    <!-- Toasts !-->
    <ToastHolder></ToastHolder>

    <!-- Hints !-->
    <HintHolder></HintHolder>

    <!-- Min width keeper !-->
    <MinWidthKeeper></MinWidthKeeper>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import ToastHolder from "@/_view/components/ToastHolder.vue";
import HintHolder from "@/help/hint/_view/HintHolder.vue";
import MinWidthKeeper from "@/_view/components/MinWidthKeeper.vue";
import UpdateBox from "@/_view/components/UpdateBox.vue";

@Component({
  components: {
    UpdateBox,
    MinWidthKeeper,
    HintHolder,
    ToastHolder
  },
})

export default class App extends Vue {

  private timeout: any = null;

  private appHeight = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }, 500);
  }

  public mounted() {
    window.addEventListener('resize', this.appHeight);
    window.addEventListener('orientationchange', this.appHeight);
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

}
</script>

<style lang="scss">
@import '@/__app_assets/scss/styles';
</style>
