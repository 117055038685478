import Model from "@/__libs/_model/Model";
import {ITeamUserBodyDto, ITeamUserStatsDto, IUserOutlineDto} from "@/team/_model/team.dto";
import {UserOrigin, UserStatus} from "@/team/_model/team.constants";
import {DtoType} from "@/_model/app.constants";
import {IModelDto} from "@/__libs/_model/model.dto";
import {LoadingStatus, SaveStatus} from "@/entity/_model/entity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";


class TeamUserModel extends Model implements IUserOutlineDto
{
    public userID:number = 0;
    public displayName:string = "";
    public email:string = "";
    public langCode:string = "en";
    public avatarFileUri:string = `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/user_avatar_default.png`;
    public teamName:string = "";

    public firstName:string = "";
    public lastName:string = "";
    public telNr:string = "";
    public mobileNr:string = "";
    public faxNr:string = "";
    public department:string = "";
    public jobTitle:string = "";
    public address:string = "";
    public website:string = "";
    public signature:string = "";

    public userOrigin:UserOrigin = UserOrigin.STATIC;
    public createdDate:Date = new Date();

    public teamID:number = 0;
    public roleID:number = 0;

    public stats?:ITeamUserStatsDto;

    //status
    public status:UserStatus  = UserStatus.UNVERIFIED;

    public loadingStatus:LoadingStatus = LoadingStatus.ID_ONLY;

    public saveStatus:SaveStatus = SaveStatus.IDLE;

    public hasChanges:boolean = false;


    //---------------------------------
    // Singleton Constructor
    //---------------------------------



    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {
            const dto:ITeamUserBodyDto = {
                email       : this.email,
                teamID       : this.teamID,
                roleID       : this.roleID,
                displayName  : this.displayName,
                langCode     : this.langCode,
                firstName    : this.firstName,
                lastName     : this.lastName,
                telNr        : this.telNr,
                mobileNr     : this.mobileNr,
                faxNr        : this.faxNr,
                department   : this.department,
                jobTitle     : this.jobTitle,
                avatarFileUri: this.avatarFileUri,
                address      : this.address,
                website      : this.website,
                signature    : this.signature
            };
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}

export default TeamUserModel;
