import Model from "@/__libs/_model/Model";
import {ITeamBodyDto} from "@/team/_model/team.dto";
import {DtoType} from "@/_model/app.constants";
import {IModelDto} from "@/__libs/_model/model.dto";
import {LoadingStatus, SaveStatus} from "@/entity/_model/entity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";


class TeamModel extends Model implements ITeamBodyDto
{
    public teamID:number = 0;
    public displayName:string = "";
    public parentTeamID:number = 0;
    public avatarFileUri:string = `assetFolder://asf-${AppUserModel.getInstance().project.identifier}-system-assets/team_avatar_default.png`;
    public countryIdentifier:string = "WW";
    public allowPersonalFromMail:boolean = false;
    public createdDate:Date = new Date();
    public availableLanguages:string[] = [];



    //status

    public loadingStatus:LoadingStatus = LoadingStatus.ID_ONLY;

    public saveStatus:SaveStatus = SaveStatus.IDLE;

    public hasChanges:boolean = false;


    //---------------------------------
    // Singleton Constructor
    //---------------------------------



    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapToDto(p_dtoType:DtoType):IModelDto
    {
        if (p_dtoType === DtoType.BODY)
        {

            if (this.availableLanguages.length === 0) //if no languages are selected, prefill the first one available
            {
                this.availableLanguages.push(AppUserModel.getInstance().project.availableLangCodes[0]);
            }
            const dto:ITeamBodyDto = {
                teamID               : this.teamID,
                displayName          : this.displayName,
                avatarFileUri        : this.avatarFileUri,
                parentTeamID         : this.parentTeamID,
                countryIdentifier    : this.countryIdentifier,
                allowPersonalFromMail: this.allowPersonalFromMail,
                availableLanguages   : this.availableLanguages
            };
            return dto;
        }
        else
        {
            return super.mapToDto(p_dtoType);
        }
    }

}

export default TeamModel;
