<template>
  <div class="box box--full-height box--scrollable audience-detail">

    <transition name="fade">
      <div v-if="!audience"
           class="empty-message">
        {{ $t('DashboardPlaceholderMessage') }}
      </div>
    </transition>

    <transition name="fade">
      <div v-if="audience" :key="`${audience.ID}`" class="box-wrapper">

        <h4 class="title mt-1 mb-4">{{ $t('AudienceDetailTitle') }}</h4>

        <b-form-group>
          <b-form-input size="lg"
                        v-model="validationAudience.displayName"
                        :disabled="!audience.isEditable || !online"
                        :placeholder="$t('PlaceholderDisplayName')"
                        :state="_calculateInputState($v.validationAudience.displayName)"
                        @input="audience.displayName = _onFormValidationInput($v.validationAudience.displayName, audience.displayName)"
                        tabindex="1"></b-form-input>
          <b-form-invalid-feedback>
            <span class="d-block"
                  v-if="!$v.validationAudience.displayName.required">{{
                $t('FieldRequiredSimple')
              }}</span>
            <span class="d-block"
                  v-if="!$v.validationAudience.displayName.minLength">{{
                $t('MinimumFieldLengthSimple', [3])
              }}</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-form-input type="email"
                        v-model="validationAudience.email"
                        :disabled="!audience.isEditable || !online"
                        :placeholder="$t('PlaceholderEmail')"
                        :state="_calculateInputState($v.validationAudience.email)"
                        @input="audience.email = _onFormValidationInput($v.validationAudience.email, audience.email)"
                        tabindex="2"></b-form-input>
          <b-form-invalid-feedback>
                        <span class="d-block" v-if="!$v.validationAudience.email.required">{{
                            $t('FieldRequiredSimple')
                          }}</span>
            <span class="d-block"
                  v-if="!$v.validationAudience.email.email">{{ $t('ErrorInvalidEmailAddress') }}</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-form-input v-model="audience.firstName"
                        :disabled="!audience.isEditable || !online"
                        :placeholder="$t('PlaceholderFirstName')"
                        @input="_onFormInput"
                        tabindex="3"></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-form-input v-model="audience.lastName"
                        :disabled="!audience.isEditable || !online"
                        :placeholder="$t('PlaceholderLastName')"
                        @input="_onFormInput"
                        tabindex="4"></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-form-select v-model="audience.langCode"
                         :disabled="!audience.isEditable || !online"
                         @change="_onFormInput" tabindex="5">
            <option v-for="langCode in availableLanguages" :key="langCode"
                    :value="langCode">{{ langCode }}
            </option>
          </b-form-select>
        </b-form-group>

        <div class="sub-box team-users-detail__box mb-3">

          <b-form-group>
            <label>
              <i class="fas fa-building"></i>
              {{ $t('AudienceDetailCompany') }}
            </label>
            <b-form-select v-model="audience.companyID"
                           :disabled="!audience.isEditable || !online"
                           @change="_onFormInput" tabindex="6">
              <option v-for="company in companies" :key="company.ID"
                      :value="company.ID">{{ company.displayName }}
              </option>
            </b-form-select>
          </b-form-group>

        </div>

        <DataProviderEditor :dataProvider="audience.dataDtp" :isStandalone="true" v-if="audience.dataDtp" class="mb-3" />

        <div class="sub-box team-users-detail__box" v-if="showGuests">

          <h6 class="d-flex"><i class="fas fa-user-friends"></i>{{ $t('Guests') }}</h6>
          <ul>
            <li v-for="guest in audience.guests" :key="guest.guestID">
              {{ guest.displayName }} <i>({{ guest.email }})</i>
            </li>
          </ul>

        </div>

      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";

import audienceController from "@/audience/_controller/AudienceController";
import languageManager from "@/__libs/language_manager/LanguageManager";
import {email, minLength, required} from "vuelidate/lib/validators";
import DataProviderEditor from "@/data_tool/data_provider/_view/DataProviderEditor.vue";
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyListModel from "@/company/_model/CompanyListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import NetworkManager from "@/_controller/NetworkManager";

@Component({
  components: {
    DataProviderEditor
  },
  validations: {
    validationAudience: {
      displayName: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      }
    }
  }
})
export default class AudienceDetail extends Vue {

  private validationAudience: AudienceModel = new AudienceModel();
  public networkManager: NetworkManager = NetworkManager.getInstance();

  get online() {
    return this.networkManager.online;
  }

  get audience(): AudienceModel | null {
    return AudienceListModel.getInstance().globalSelState.selected;
  }

  get availableLanguages(): string[] {
    return languageManager.availableLangCodes;
  }

  get companies(): CompanyModel[] {
    const noCompany: CompanyModel = new CompanyModel({displayName: this.$t('NoCompany')});
    return [noCompany, ...CompanyListModel.getInstance().list];
  }

  get hasChanges() {
    return this.audience && this.audience.hasChanges;
  }

  get showGuests(): boolean {
    return AppUserModel.getInstance().project.tierConfig.hasGuests && this.audience!.guests!.length > 0;
  }

  public beforeDestroy() {
    if (this.audience && this.audience.hasChanges) {
      this._saveAudience(this.audience);
    }
  }

  private _calculateInputState(inputValidator: any): any {
    return inputValidator.$invalid ? false : null;
  }

  private _saveAudience(audience: AudienceModel): void {
    audienceController.saveBody(audience);
  }

  private _createValidationAudience(audience: AudienceModel): void {
    if (this.audience) {
      this.validationAudience = new AudienceModel();
      this.validationAudience.displayName = audience.displayName;
      this.validationAudience.email = audience.email;
    }
  }

  private _onFormValidationInput(inputValidator: any, value: any): void {
    if (!inputValidator.$invalid && this.audience) {
      value = inputValidator.$model;
      this._onFormInput(null);
    }
    return value;
  }

  private _onFormInput(p_e: Event | null) {
    if (this.audience) {
      this.audience.hasChanges = true;
    }
  }

  @Watch('audience', {immediate: true, deep: false})
  private _onActiveAudienceChange(newAudience: AudienceModel | null, oldAudience: AudienceModel | null) {
    if (oldAudience && oldAudience.hasChanges) {
      this._saveAudience(oldAudience);
    }
    if (newAudience) {
      this._createValidationAudience(newAudience);
      audienceController.fetchGuests(newAudience);

    }
  }

  //todo: also watch window.beforeunload

}
</script>
