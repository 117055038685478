export enum EngineMode
{
    PREVIEW = "PREVIEW",
    PREPARE = "PREPARE",
    PRESENT = "PRESENT",
    TRAIN = "TRAIN",
    PRESENTATION_EDITOR = "PRESENTATION_EDITOR",
    SLIDE_EDITOR = "SLIDE_EDITOR",
    CONSTRUCT_EDITOR = "CONSTRUCT_EDITOR",
    PRESENTER_VIEW = "PRESENTER_VIEW",
    SLIDE_REEL = "SLIDE_REEL",
    SINGLE_SLIDE = "SINGLE_SLIDE",
    ANALYTICS = "ANALYTICS",
    TEMPLATE_OVERVIEW = "TEMPLATE_OVERVIEW",
    LIBRARY_SLIDE_OVERVIEW = "LIBRARY_SLIDE_OVERVIEW",
    CONTENT_APP_EDITOR = "CONTENT_APP_EDITOR",
}


export enum ApiAction
{
    SLIDE = "SLIDE",
    CHAPTER = "CHAPTER",
    NAVIGATE = "NAVIGATE",
    ACTION = "ACTION",
    URL = "URL",
    DECK = "DECK",
    LANGUAGE = "LANGUAGE",

    OPEN_PRESENTATION = "OPEN_PRESENTATION",
    OPEN_CONTENT_FILE = "OPEN_CONTENT_FILE",
    OPEN_CONTENT_APP = "OPEN_CONTENT_APP",


    DOCUMENT = "DOCUMENT",
    FEEDBACK = "FEEDBACK",
    PREVIEW = "PREVIEW"
}

export enum EngineMessageType
{
    //from presentation to app
    REGISTER_AUDIENCE_SESSION = "REGISTER_AUDIENCE_SESSION",
    REGISTER_AUDIENCE_SESSION_EVENT = "REGISTER_AUDIENCE_SESSION_EVENT",
    ASK_EXIT = "ASK_EXIT",
    REPORT_ERROR = "REPORT_ERROR",
    ASK_SAVE = "ASK_SAVE",
    ASK_SAVE_AND_EXIT = "ASK_SAVE_AND_EXIT",
    ASK_PATCH_AUDIENCE = "ASK_PATCH_AUDIENCE",
    ASK_SHARE_WITH_AUDIENCE = "ASK_SHARE_WITH_AUDIENCE",
    ASK_DATAPROVIDER_PICKER = "ASK_DATAPROVIDER_PICKER",
    ASK_LINK_WIZARD = "ASK_LINK_WIZARD",
    ASK_ASSET_FILE_PICKER = "ASK_ASSET_FILE_PICKER",
    ASK_PRESENT_CONTENT = "ASK_PRESENT_CONTENT",
    ASK_EDIT_CONTAINER_PROPERTY = "ASK_EDIT_CONTAINER_PROPERTY",
    BROADCAST_MOUSE_POSITION = "BROADCAST_MOUSE_POSITION",

    // from app to presentation
    SAVE_AND_EXIT = "SAVE_AND_EXIT",
    SET_DATAPROVIDER = "SET_DATAPROVIDER",
    SET_LINK = "SET_LINK",
    SET_ASSET_FILE = "SET_ASSET_FILE",
    SET_CONTAINER_PROPERTY_VALUE = "SET_CONTAINER_PROPERTY_VALUE",
}


export enum NavigateType
{
    HOME = "HOME",
    END = "END",
    NEXT_SLIDE = "NEXT_SLIDE",
    PREVIOUS_SLIDE = "PREVIOUS_SLIDE",
}
