import ContentFolderModel from "@/content/_model/ContentFolderModel";
import Dictionary from "@/__libs/_model/Dictionary";
import {IContentFolderDto} from "@/content/_model/content.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {BreadCrumbType} from "@/content/_model/content.constants";
import ContentModel from "@/content/_model/ContentModel";
import {RIGHTS} from "@/team/_model/role.constants";

class ContentFolderListModel
{


    public rootContentFolder:ContentFolderModel;

    private _activeContentFolder:ContentFolderModel;
    get activeContentFolder():ContentFolderModel
    {
        return this._activeContentFolder;
    }

    set activeContentFolder(value:ContentFolderModel)
    {
        this._activeContentFolder = value;
        this.setBreadCrumb();

    }

    public breadCrumbs:ContentFolderModel[] = [];

    public contentFoldersDict:Dictionary<ContentFolderModel, number> = new Dictionary<ContentFolderModel, number>();

    public inSearchMode:boolean = false;

    public currentDragged:ContentModel | ContentFolderModel | null = null;
    public dragInProgress:boolean = false;


    //---------------------------------
    // Singleton Constructor
    //---------------------------------

    private static _instance:ContentFolderListModel;

    constructor()
    {
        if (!ContentFolderListModel._instance)
        {
            ContentFolderListModel._instance = this;
        }
        this.rootContentFolder = new ContentFolderModel(this);
        this.rootContentFolder.body.name[AppUserModel.getInstance().langCode] = "HOME";
        this.rootContentFolder.activeBreadCrumbType = BreadCrumbType.HOME;
        this.rootContentFolder.isEditable = true;
        this._activeContentFolder = this.rootContentFolder;
        this.setBreadCrumb();
    }

    public static getInstance():ContentFolderListModel
    {
        if (!ContentFolderListModel._instance)
        {
            new ContentFolderListModel();
        }

        return ContentFolderListModel._instance;
    }


    //---------------------------------
    // Public Methods
    //---------------------------------

    public mapFoldersFromDto(p_contentFolders:IContentFolderDto[])
    {
        const shareableTeamsIDList:number[] = [];
        for (let i = 0; i < AppUserModel.getInstance().shareableTeams.length; i++)
        {
            shareableTeamsIDList.push(AppUserModel.getInstance().shareableTeams[i].teamID);
        }

        //make models, put them in the  dictionary for reference
        for (let i = 0; i < p_contentFolders.length; i++)
        {
            const contentFolder:ContentFolderModel = new ContentFolderModel(this);
            contentFolder.mapFromDto(p_contentFolders[i]);
            this.contentFoldersDict.setItem(contentFolder, contentFolder.folderID);

            //determine whether it is editable or not (try to prove true)
            // Folders are editable if they are your own, or if you have MANAGE_TEAM_FOLDERS rights + it is a folder of your team or one of your subteams: it’s teamID is in your shareableTeams list)

            if (contentFolder.createdByUserID === AppUserModel.getInstance().ID)
            {
                contentFolder.isEditable = true;
            }
            else
            {
                if (AppUserModel.getInstance().rights.indexOf(RIGHTS.MANAGE_TEAM_FOLDERS.identifier) >= 0)
                {
                    // console.log("has folder manage rights for ", shareableTeamsIDList);
                    if (shareableTeamsIDList.includes(contentFolder.createdByTeamID))
                    {
                        contentFolder.isEditable = true;
                    }
                }
            }
            // console.log(contentFolder.body.name.en, contentFolder.isEditable);
        }

        const contentFolders:ContentFolderModel[] = this.contentFoldersDict.items;
        for (let i = 0; i < contentFolders.length; i++)
        {
            const contentFolder:ContentFolderModel = contentFolders[i];
            //check if parent is present, assign to parent,  if not, assign to root
            let parentFolder:ContentFolderModel | null = null;
            if (contentFolder.parentFolderID)
            {
                parentFolder = this.contentFoldersDict.getItem(contentFolder.parentFolderID);
            }
            if (parentFolder)
            {
                parentFolder.childFolders.push(contentFolder);
            }
            else
            {
                //assign to root
                contentFolder.parentFolderID = 0;
                this.rootContentFolder.childFolders.push(contentFolder);
            }
        }
    }

    public setBreadCrumb()
    {
        this.breadCrumbs = [this._activeContentFolder];
        this.addBreadCrumbLevel(this._activeContentFolder, this.breadCrumbs);
        if (this.breadCrumbs[0] !== this.rootContentFolder)
        {
            this.breadCrumbs.unshift(this.rootContentFolder);
        }
        if (this._activeContentFolder.activeBreadCrumbType !== BreadCrumbType.HOME)
        {
            this._activeContentFolder.activeBreadCrumbType = BreadCrumbType.ACTIVE;
        }
    }

    public addBreadCrumbLevel(p_folder:ContentFolderModel, p_path:ContentFolderModel[])
    {
        let parentFolder:ContentFolderModel | null = null;
        if (p_folder.parentFolderID)
        {
            parentFolder = this.contentFoldersDict.getItem(p_folder.parentFolderID);

            if (this._activeContentFolder.activeBreadCrumbType !== BreadCrumbType.HOME)
            {
                this._activeContentFolder.activeBreadCrumbType = BreadCrumbType.LEVEL;
            }
            parentFolder.activeBreadCrumbType = BreadCrumbType.LEVEL;
            p_path.unshift(parentFolder);
            this.addBreadCrumbLevel(parentFolder, p_path);
        }
    }

    public moveContentToFolder(p_content:ContentModel, p_targetFolder:ContentFolderModel)
    {
        let currentFolder:ContentFolderModel;
        if (p_content.folderID)
        {
            currentFolder = this.contentFoldersDict.getItem(p_content.folderID);
        }
        else
        {
            currentFolder = this.rootContentFolder;
        }

        const index:number = currentFolder.childContents.indexOf(p_content);
        if (index >= 0)
        {
            currentFolder.childContents.splice(index, 1);
        }
        p_content.folderID = p_targetFolder.folderID;
        p_targetFolder.childContents.push(p_content);

    }

    public moveFolderToFolder(p_movedFolder:ContentFolderModel, p_targetFolder:ContentFolderModel)
    {
        let parentFolder:ContentFolderModel;
        if (p_movedFolder.parentFolderID)
        {
            parentFolder = this.contentFoldersDict.getItem(p_movedFolder.parentFolderID);
        }
        else
        {
            parentFolder = this.rootContentFolder;
        }

        const index:number = parentFolder.childFolders.indexOf(p_movedFolder);
        if (index >= 0)
        {
            parentFolder.childFolders.splice(index, 1);
        }
        p_movedFolder.parentFolderID = p_targetFolder.folderID;
        p_targetFolder.childFolders.push(p_movedFolder);

        if (p_movedFolder === this._activeContentFolder)
        {
            console.log("dragging active folder in breadcrumbs");
            //todo breadcrumbs not updating (except when dragging to root)
            // this.setBreadCrumb();
        }


    }


    public deleteFolder(p_folder:ContentFolderModel)
    {
        let parentFolder:ContentFolderModel;
        if (p_folder.parentFolderID)
        {
            parentFolder = this.contentFoldersDict.getItem(p_folder.parentFolderID);
        }
        else
        {
            parentFolder = this.rootContentFolder;
        }
        const index:number = parentFolder.childFolders.indexOf(p_folder);
        if (index >= 0)
        {
            parentFolder.childFolders.splice(index, 1);
        }

        //move child content to root
        for (let i = 0; i < p_folder.childContents.length; i++)
        {
            const childContent:ContentModel = p_folder.childContents[i];
            childContent.folderID = 0;
            this.rootContentFolder.childContents.push(childContent);
        }
        //move child folders to root
        for (let i = 0; i < p_folder.childFolders.length; i++)
        {
            const childFolder:ContentFolderModel = p_folder.childFolders[i];
            childFolder.parentFolderID = 0;
            this.rootContentFolder.childFolders.push(childFolder);
        }
    }

    //empty all folders' childfolders and content, so that it can be populated again
    public clearFolders()
    {
        this.rootContentFolder.clearChildren();
        const folders:ContentFolderModel[] = this.contentFoldersDict.items;
        for (let i = 0; i < folders.length; i++)
        {
            const folder = folders[i];
            folder.clearChildren();
        }
    }

    public getFolderByID(p_folderID:number):ContentFolderModel | null
    {
        if (this.contentFoldersDict.keyExists(p_folderID))
        {
            return this.contentFoldersDict.getItem(p_folderID);
        }
        return null;
    }


}

export default ContentFolderListModel;
