import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IAppUserSessionDto, IDomainInfoDto, IUserUsageSessionDto} from "@/_model/app.dto";
import AppModel from "@/_model/AppModel";
import fileManager from "@/_controller/FileManager";
import languageManager from "@/__libs/language_manager/LanguageManager";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {CognitoAuth, CognitoToken} from "amazon-cognito-auth-ts/lib";
import {CognitoAuthOptions} from "amazon-cognito-auth-ts/lib/CognitoAuth";
import CognitoAuthSession from "amazon-cognito-auth-ts/lib/CognitoAuthSession";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import {DtoType, LocalStorageKey} from "@/_model/app.constants";
import JsonUtil from "@/__libs/utility/JsonUtil";
import {IResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import Util from "@/__libs/utility/Util";
import NetworkManager from "@/_controller/NetworkManager";
import SyncDb from "@/sync/_model/SyncDb";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import {
    ICobrowseRoomUserDto,
    ICobrowseUserDto
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.dto";
import {
    CobrowseUserInteractionState,
    CobrowseUserType
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
import {ICobrowseConnectionDto} from "@sales-drive/audience-library/lib/cobrowse_manager/_model/cobrowse.dto";
import {
    CobrowseConnectionIdentifier
} from "@sales-drive/audience-library/lib/cobrowse_manager/_model/cobrowse.constants";
import {IRoomDto} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.dto";
import {CobrowseRoomType} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";

class AppUserController
{


    public inImpersonationMode:boolean = false;
    public impersonationToken:string = "";
    private _impersonatedByUserID:number | null = null;

    private _appUserModel:AppUserModel = AppUserModel.getInstance();
    private _appModel:AppModel = AppModel.getInstance();
    private _cobrowseManager:CobrowseManager = CobrowseManager.getInstance();


    private _refreshInterval:any;
    private _refreshTime:number = 3000000;

    private _syncDb:SyncDb = SyncDb.getInstance();

    private _auth!:CognitoAuth;
    get auth():CognitoAuth
    {
        if (!this._auth)
        {
            const domainInfo:IDomainInfoDto | null = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.DOMAIN_INFO) as string);

            const protocol:string = new URL(window.location.href).protocol;

            if (domainInfo)
            {
                const authOptions:CognitoAuthOptions = {
                    ClientId          : domainInfo.cognitoClientId,
                    AppWebDomain      : domainInfo.cognitoLoginDomain,
                    TokenScopesArray  : ['openid', 'email'],
                    RedirectUriSignIn : `${protocol}//${domainInfo.domain}/login-callback.html`,
                    RedirectUriSignOut: `${protocol}//${domainInfo.domain}/logout.html`,
                    IdentityProvider  : domainInfo.cognitoProvider,
                    UserPoolId        : domainInfo.cognitoUserPoolId,
                    Storage           : null
                };
                this._auth = new CognitoAuth(authOptions, false);
            }
            else
            {
                console.log("couldn't read domainInfo out of local storage");
            }
        }
        return this._auth;
    }

    //---------------------------------
    // Controller Methods
    //---------------------------------

    private async _forceLoginUser()
    {
        console.log('Force re-login user');
        this.auth.launchUri(this.auth.getFQDNSignIn());
        await Util.timeOut(2000);
    }

    public async loginUser():Promise<boolean>
    {
        try
        {
            const session:CognitoAuthSession = await this.auth.getSession();
            if (!session)
            {
                // wait 2 seconds: if loginUser gives back false -> probably doing redirect
                await Util.timeOut(2000);
                return false;
            }
            else
            {
                if (session.isValid())
                {
                    return await this.verifyFederated(session.getAccessToken().jwtToken);
                }
                else
                {
                    return false;
                }
            }
        } catch (e)
        {
            console.log('error while login, probably expiration of refresh token');
            await this._forceLoginUser();
            return false;
        }
    }

    public async loginUserParseResponse(url:string):Promise<boolean>
    {
        try
        {
            const session:CognitoAuthSession = await this.auth.parseCognitoWebResponse(url);
            // console.log("after redirect",  session.getIdToken().decodePayload());
            if (session)
            {
                return await this.verifyFederated(session.getAccessToken().jwtToken);
            }
            else
            {
                return false;
            }
        } catch (e)
        {
            return false;
        }
    }


    private async verifyFederated(p_accessToken:string):Promise<boolean>
    {
        try
        {
            //check if user is federated, and then try to verify him/her (update the sub by crosschecking with email)
            if (this.auth.identityProvider && this.auth.identityProvider.length > 0)
            {
                //try to verify him/her with access token
                const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/users/sso/`, {accessToken: p_accessToken});
                return response.hasSucceeded;
            }
            return true;
        } catch (e)
        {
            return false;
        }
    }


    public async getAuthToken():Promise<string | null>
    {
        try
        {
            if (this.inImpersonationMode)
            {
                return this.impersonationToken;
            }
            //only try to get the session when online, otherwise the app will jump to the Cognito login screen and you will get a"no internet" error
            if (NetworkManager.getInstance().online)
            {
                const session:CognitoAuthSession = await this.auth.getSession();
                if (session)
                {
                    return session.getAccessToken().jwtToken;
                }
            }
        } catch (e)
        {
            console.log('Error get auth token: ', e);
            await this._forceLoginUser();
        }
        return null;
    }

    public async logoutUser()
    {
        this.auth.signOut();
        await this.endAppUserSession();
    }

    private parseStorageUrls(appUserSessionDto:IAppUserSessionDto):IAppUserSessionDto
    {
        const appUrl:URL = new URL(window.location.href);

        if (appUrl.host.includes('localhost:8080') || appUrl.host.includes('aac.test'))
        {
            return appUserSessionDto;
        }

        const globalStorageUrl:URL = new URL(appUserSessionDto.global.storageUrl);
        appUserSessionDto.global.storageUrl = appUrl.origin + globalStorageUrl.pathname;

        const projectStorageUrl:URL = new URL(appUserSessionDto.project.storageUrl);
        appUserSessionDto.project.storageUrl = appUrl.origin + projectStorageUrl.pathname;

        return appUserSessionDto;
    }

    public async startRemoteAppUserSession():Promise<boolean>
    {

        //todo
        const createSessionDto:any = {
            "appVersion"          : this._appModel.version,
            "deviceIdentifier"    : "",
            "runtime"             : "UNKNOWN",
            "impersonatedByUserID": this._impersonatedByUserID
        };

        const response:ApiResponse<IAppUserSessionDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/users/sessions`, createSessionDto);

        if (response.hasSucceeded)
        {
            let appUserSessionDto:IAppUserSessionDto = response.result as IAppUserSessionDto;

            // parse storage urls
            appUserSessionDto = this.parseStorageUrls(appUserSessionDto);

            this._appUserModel.mapFromDto(appUserSessionDto.user);

            // console.log("storageUrl:", appUserSessionDto.project.storageUrl );
            // console.log(process.env.NODE_ENV);


            // TEMP
            // appUserSessionDto.global.storageUrl = 'https://static.dev.audience-advantage.app/storage/_global';
            // appUserSessionDto.project.storageUrl = 'https://static.dev.audience-advantage.app/storage/nespresso';

            if (!this.inImpersonationMode)
            {
                LocalStorageManager.storeValue(LocalStorageKey.USER_SESSION, JsonUtil.stringify(appUserSessionDto));

                // Start refresh circle, here?
                this._startRefreshSessionCycle();
                if (appUserSessionDto.project.config.hasCobrowse)
                {
                    this._initCobrowse(appUserSessionDto.global.cobrowseUrl!);
                }
            }
            else
            {

                return this.setAppUserSession(appUserSessionDto);
            }
        }

        return this.setAppUserSession();

    }

    private async _initCobrowse(p_cobrowseUrl:string)
    {

        const cobrowseUser:ICobrowseUserDto = {
            cobrowseUserID: CobrowseUserType.USER + "-" + this._appUserModel.ID,
            type          : CobrowseUserType.USER,
        };

        //init the connection
        const cobrowseConnection:ICobrowseConnectionDto = {
            cobrowseUser        : cobrowseUser,
            authToken           : await this.getAuthToken() as string,
            projectIdentifier   : this._appUserModel.project.identifier,
            connectionIdentifier: CobrowseConnectionIdentifier.PWA
        };
        this._cobrowseManager.init(p_cobrowseUrl, cobrowseConnection);

        //join project room
        const room:IRoomDto = {
            identifier: `${this._appUserModel.project.identifier}:${CobrowseRoomType.PROJECT}`,
            roomType  : CobrowseRoomType.PROJECT
        };
        this._cobrowseManager.askRoom(room, cobrowseUser);

    }

    public joinPortalRoom(p_audienceID:string)
    {
        //join portal room
        const room:IRoomDto = {
            identifier: `${this._appUserModel.project.identifier}:${CobrowseRoomType.PORTAL}:${p_audienceID}`,
            roomType  : CobrowseRoomType.PORTAL
        };
        const roomUser:ICobrowseRoomUserDto = {
            cobrowseUserID  : CobrowseUserType.USER + "-" + this._appUserModel.ID,
            type            : CobrowseUserType.USER,
            displayName     : this._appUserModel.displayName,
            avatarUri       : this._appUserModel.avatarFileUri,
            color           : Util.getRandomColor(), //todo should come from avatar?
            interactionState: CobrowseUserInteractionState.NONE, //todo should depend upon usertype (only USER should be able to do this)
            userMouse       : {mouseY: 0, mouseX: 0, scale: 1, leftOffset:0, topOffset:0} //declare all nested properties to ensure reactiveness in Vue
        };
        this._cobrowseManager.askRoom(room, roomUser);
    }

    public setAppUserSession(p_appUserSessionDto?:IAppUserSessionDto)
    {

        const appUserSessionDto:IAppUserSessionDto | null = p_appUserSessionDto ? p_appUserSessionDto : JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.USER_SESSION) as string);

        if (appUserSessionDto)
        {
            this._appUserModel.mapFromDto(appUserSessionDto.user);

            this._appUserModel.sessionID = appUserSessionDto.sessionID;
            this._appUserModel.shareableTeams = appUserSessionDto.shareableTeams.sort(function (a, b) {
                if (a.displayName < b.displayName) {
                    return -1;
                }
                if (a.displayName > b.displayName) {
                    return 1;
                }
                return 0;
            })
            this._appUserModel.rights = appUserSessionDto.rights;

            this._appUserModel.project.mapFromDto(appUserSessionDto.project);
            this._appUserModel.project.setupPaths();

            this._appModel.serverTime = appUserSessionDto.serverTime;
            this._appModel.global = appUserSessionDto.global;

            fileManager.projectUrl = appUserSessionDto.project.storageUrl;
            fileManager.globalUrl = appUserSessionDto.global.storageUrl;
            languageManager.availableLangCodes = appUserSessionDto.availableLanguages;

            ContentFolderListModel.getInstance().mapFoldersFromDto(appUserSessionDto.contentFolders);

            // skins
            // this._appUserModel.appLogo = appUserSessionDto.project.storageUrl + '/assetFolders/asf-' + this._appUserModel.project.identifier + '-system-assets/logo-dark-bg.png';
            // @ts-ignore
            // document.querySelector(':root').style.setProperty('--primary', '#000000');

            this._appUserModel.isAuthenticated = true;

            return true;
        }
        else
        {
            return false;
        }

    }

    public async endAppUserSession()
    {
        //clear appUserModel

        // send end session api call
    }

    public async saveUser():Promise<boolean>
    {
        const response:ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, `/client-api/users/${this._appUserModel.ID}`, this._appUserModel.mapToDto(DtoType.BODY));
        if (response.hasSucceeded)
        {
            this._appUserModel.hasChanges = false;
        }
        return response.hasSucceeded;
    }


    public setImpersonationMode(p_impersonationToken:string, p_impersonatedByUserID:number)
    {
        this.inImpersonationMode = true;
        this.impersonationToken = p_impersonationToken;
        this._impersonatedByUserID = p_impersonatedByUserID;
    }


    public async registerUsageSession()
    {
        const usageSession:IUserUsageSessionDto = {
            userID       : this._appUserModel.ID,
            userSessionID: this._appUserModel.sessionID,
            clientDate   : new Date(),
            isOffline    : false
        };
        const endpoint:string = `/client-api/users/sessions/usage`;
        const response:ApiResponse<IResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endpoint, usageSession);

        if (!response.hasSucceeded)
        {
            console.log("couldn't register usage session, adding to background sync");
            usageSession.isOffline = true;
            await this._syncDb.addBackgroundSyncRecord({
                requestType: ApiRequestType.POST, endpoint: endpoint, data: usageSession
            });
        }
    }

    //---------------------------------
    // REFRESH CYCLE
    //---------------------------------

    private _startRefreshSessionCycle()
    {
        if (!this._refreshInterval)
        {
            console.log('AppUserController :: starting refreshing token cycle...');
            this._refreshInterval = window.setInterval(() => {
                this._refreshSession();
            }, this._refreshTime);
        }
    }

    private async _refreshSession():Promise<any>
    {
        console.log('AppUserController :: refreshing token...');
        if (NetworkManager.getInstance().online)
        {
            try
            {
                const session:CognitoAuthSession = await this.auth.getSession();
                if (session)
                {
                    const refreshToken:string = session.getRefreshToken().getToken();
                    await this.auth.refreshSession(refreshToken);
                    return true;
                }
                else
                {
                    await Util.timeOut(2000);
                    await this._forceLoginUser();
                }
            } catch (e)
            {
                console.log('AppUserController :: error refreshing token');
                await this._forceLoginUser();
            }
        }
        return false;
    }
}

//Singleton export
export default new AppUserController();
