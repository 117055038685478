<template>
  <div class="content-breadcrumb-item"
       :class="{ active: isActiveBreadcrumb, noDropAllowed: showNoDropAllowed, dropAttract: showDropAttract, dropFinished: dropFinished}"
       :draggable="contentFolder.isEditable"
       @dragstart="_onDragStart" @dragend="_onDragEnd" @dragover="_onDragOver"
       @dragenter="_onDragEnter" @dragleave="_onDragLeave" @drop="_onDrop" @click="_onClick"
  >
    <i v-if="contentFolder.activeBreadCrumbType !== breadCrumbType.HOME" class="fas fa-folder content-breadcrumb-item__icon"></i>
    <h6 class="content-breadcrumb-item__name mb-0" v-html="contentFolderName"></h6>
    <i class="fas fa-caret-right content-breadcrumb-item__arrow" v-if="!isActiveBreadcrumb"></i>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import fileManager from "@/_controller/FileManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import {BreadCrumbType} from "@/content/_model/content.constants";
import ContentModel from "@/content/_model/ContentModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import contentFolderController from "@/content/_controller/ContentFolderController";
import contentController from "@/content/_controller/ContentController";

@Component({
  components: {}
})
export default class ContentBreadCrumbItemRenderer extends Vue {

  @Prop() public contentFolder!: ContentFolderModel;

  private breadCrumbType: typeof BreadCrumbType = BreadCrumbType;

  public isBeingDraggedOver: boolean = false;
  public dragCount: number = 0;
  public dropFinished: boolean = false;

  get isActiveBreadcrumb() {
    return this.contentFolder.activeBreadCrumbType === BreadCrumbType.ACTIVE
  }

  get contentFolderName() {
    return languageManager.getTranslationForValue(
        this.contentFolder.body.name,
        this.audienceLangCode
    );
  }

  get folderID() {
    return this.contentFolder.folderID;
  }


  get posterUrl() {
    const posterUri: string = languageManager.getTranslationForValue(
        this.contentFolder.body.poster,
        this.audienceLangCode
    );
    return fileManager.getFileUrl(posterUri);
  }

  get audienceLangCode(): string | null {
    const activeAudience: AudienceModel | null = AudienceListModel.getInstance()
        .globalSelState.selected;
    return activeAudience ? activeAudience.langCode : null;
  }


  get showNoDropAllowed() {
    // check if currentDragged is folder or content  (when it is content, you should not take isEditable into account)
    let currentDragged: ContentModel | ContentFolderModel | null = ContentFolderListModel.getInstance().currentDragged;
    if (currentDragged instanceof ContentFolderModel) {
      return ContentFolderListModel.getInstance().dragInProgress && !this.contentFolder.isEditable;
    }
    return false;
  }

  get showDropAttract() {
    return this.isBeingDraggedOver && !this.showNoDropAllowed;
  }

  public mounted() {
  }

  public _onClick(p_e: Event) {
    this.$emit("onClick", this.contentFolder);
  }

  private _onDragStart() {
    contentFolderController.startDrag(this.contentFolder);
  }

  private _onDragEnd() {
    contentFolderController.endDrag();
  }

  private _onDragOver(p_event: DragEvent) {
    let isDroppable: boolean = true;
    //check if it is a content or a folder that is being dragged, because you can only drop another folder here if this folder is editable
    // (and if the dragged folder is editable, but the targetfolder draggable is only true when it is editable itself)
    let currentDragged: ContentModel | ContentFolderModel | null = ContentFolderListModel.getInstance().currentDragged;
    if (currentDragged instanceof ContentFolderModel) {
      isDroppable = this.contentFolder.isEditable;
    }
    if (isDroppable) {
      p_event.preventDefault();
      if (p_event.dataTransfer) {
        p_event.dataTransfer.dropEffect = "move";
      }
    }
  }

  private _onDragEnter(p_event: DragEvent) {
    this.dropFinished = false;
    this.dragCount++;
    this.isBeingDraggedOver = true;
  }

  private _onDragLeave(p_event: DragEvent) {
    this.dragCount--;
    if (this.dragCount == 0) {
      this.isBeingDraggedOver = false;
    }
  }

  private _onDrop() {
    let currentDragged: ContentModel | ContentFolderModel | null = ContentFolderListModel.getInstance().currentDragged;
    if (currentDragged instanceof ContentModel) {
      contentController.moveContentToFolder(currentDragged, this.contentFolder);

    } else if (currentDragged instanceof ContentFolderModel) {
      if (currentDragged !== this.contentFolder) {
        contentFolderController.moveFolderToFolder(currentDragged, this.contentFolder)
      }
    }
    contentFolderController.endDrag();

    // reset drop count hack
    this.dragCount = 0;
    this.isBeingDraggedOver = false;

    // do animation
    this.dropFinished = true;
  }
}
</script>
