<template>
  <div class="player presentation-player">
    <iframe class="player__frame" ref="playerFrame" v-on:load="_onIframeLoad()"></iframe>

    <MousePointerLayer v-if="cobrowseManager.activeRoom" :room="cobrowseManager.activeRoom"></MousePointerLayer>

    <div class="player__back" @click="_onBackButtonClick">
      <div class="player__back__background"></div>
      <i class="fas fa-chevron-left"></i>
    </div>

    <b-modal id="dataprovider-picker-box" size="xl" :title="$t('PickDataOrCreateNewOne')" hide-footer>
      <DataProvidersPicker
          :selectedDtpUri="selectedDtpUri"
          :allowedSchema="allowedSchema"
          v-on:onDtpPicked="_onDtpPicked"></DataProvidersPicker>
    </b-modal>

    <b-modal id="link-wizard-box" size="xl" :title="$t('LinkWizard')" hide-footer>
      <LinkWizardBox
          :linkWizardDto="linkWizardDto"
          v-on:onLinkChosen="_onLinkChosen"></LinkWizardBox>
    </b-modal>

    <b-modal id="asset-picker-box" size="xl" :title="$t('PickFileOrUploadNewOne')" hide-footer>
      <AssetFoldersPicker :selectedFileUri="selectedFileUri"
                          :allowedTargetType="fileAllowedTargetType"
                          :allowedFileTypes="fileAllowedFileTypes"
                          v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
    </b-modal>

    <b-modal id="edit-container-property-box" size="xl" :title="$t('EditContainer')" hide-footer>
      <EditContainerPropertyBox
          :containerPropertyValueDto="containerPropertyValueDto"
          v-on:onContainerPropertyValueSet="_onContainerPropertyValueSet"></EditContainerPropertyBox>
    </b-modal>
  </div>


</template>

<!--Webview url params voor presentation editor:-->
<!--?envmode=APP&userid={userID}&engmode=PRESENTATION_EDITOR&presid={presentationID}-->

<!--Webview url params voor analytics mode:-->
<!--?envmode=APP&engmode=ANALYTICS&asi={audienceSessionIdentifier}&userid={userID}&apiurl={apiUrl}&authtoken={authToken}-->

<!--http://audience/_audienceENGINE/test/_projects/demo/constructs/default/?presid=prs-874-acc0e064-1bb3-4d47-b502-ccb5bfcf1951&audienceid=aud-875-48e3929b-0ab8-4e42-966a-d24cf57258e8&engmode=PRESENT&storageurl=http://d30cgphjipth2a.cloudfront.net/tom/demo/-->


<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IContainerPropertyValueDto, IEngineMessageDto} from "@/presentation/player/_model/player.dto";
import {ApiAction, EngineMessageType, EngineMode} from "@/presentation/player/_model/player.constants";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import PresentationModel from "@/presentation/_model/PresentationModel";
import ContentListModel from "@/content/_model/ContentListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import contentController from "@/content/_controller/ContentController";
import appController from "@/_controller/AppController";
import appUserController from "@/project/user/_controller/AppUserController";
import {RoutingIdentifier} from "@/router";
import SlideListModel from "@/slide/_model/SlideListModel";
import SlideModel from "@/slide/_model/SlideModel";
import slideController from "@/slide/_controller/SlideController";
import DataProvidersPicker from "@/data_tool/data_provider/_view/DataProvidersPicker.vue";
import {IDataProviderPickerDto} from "@/data_tool/_model/data_tool.dto";
import {ILinkValueDto, ILinkWizardDto} from "@/presentation/_model/presentation.dto";
import LinkWizardBox from "@/content/_view/LinkWizardBox.vue";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {
  FILE_TYPE_CONFIG,
  FileTargetType,
  FileType,
  IFileTypeConfig
} from "@/asset_folder/_model/asset_folder.constants";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import {DtoType} from "@/_model/app.constants";
import {RIGHTS} from "@/team/_model/role.constants";
import ContentModel from "@/content/_model/ContentModel";
import {EntityType} from "@/entity/_model/entity.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import {SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import fileManager from "@/_controller/FileManager";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";
import CobrowseManager from '@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager';
import {
  IPresentationSoDataDto
} from '@sales-drive/audience-library/lib/cobrowse_manager/presented_content/_model/presented_content.dto';

import EditContainerPropertyBox from "@/presentation/player/_view/EditContainerPropertyBox.vue";
import {
  SharedObjectType
} from '@sales-drive/audience-library/lib/cobrowse_manager/shared_object/shared_object.constants';
import SharedObject from '@sales-drive/audience-library/lib/cobrowse_manager/shared_object/SharedObject';
import {
  CobrowseUserInteractionState
} from '@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants';
import MousePointerLayer from '@/cobrowse/room/_view/MousePointerLayer.vue';


@Component({
  components: {DataProvidersPicker, LinkWizardBox, AssetFoldersPicker, EditContainerPropertyBox,  MousePointerLayer}
})
export default class PresentationPlayer extends Vue
{

  @Prop() private engineMode!:EngineMode;

  @Prop() private presentationID?:string;
  @Prop() private slideID?:string;

  @Prop() private audienceSessionIdentifier?:string;

  @Prop() private useConstruct?:string;

  public exitAllowed:boolean = true;


  //dataPicker
  public selectedDtpUri:string = "dataProvider://dtp-9415-6f5808f1-3a9c-441e-a2f8-1169c347c0ea"
  public allowedSchema:string = "dataSchema://country_simple_schema";

  //linkWizard
  public linkWizardDto?:ILinkWizardDto = {} as ILinkWizardDto;

  //asset picker
  public fileAllowedTargetType:FileTargetType = FileTargetType.PRESENTATION_CONTENT;
  public fileAllowedFileTypes:FileType[] = [FileType.IMAGE];
  public selectedFileUri:string = "";

  private cobrowseManager:CobrowseManager = CobrowseManager.getInstance();

  //containerEditor
  public containerPropertyValueDto?:IContainerPropertyValueDto = {} as IContainerPropertyValueDto;

  get audience():AudienceModel | null
  {
    return AudienceListModel.getInstance().globalSelState.selected;
  }

  get presentation():PresentationModel | null
  {
    if (this.presentationID)
    {
      return ContentListModel.getInstance().getEntityByID(this.presentationID) as PresentationModel;
    }
    return null;
  }

  get slide():SlideModel | null
  {
    if (this.slideID)
    {
      return SlideListModel.getInstance().getEntityByID(this.slideID) as SlideModel;
    }
    return null;
  }

  mounted()
  {
    window.addEventListener('message', this.listenToEngine);
    window.addEventListener('beforeunload', this._beforeUnload);

    this._setPlayerUrl();
    this.initCobrowse();
  }

  beforeDestroy()
  {

    window.removeEventListener('message', this.listenToEngine);
    window.removeEventListener('beforeunload', this._beforeUnload)

  }

  private initCobrowse()
  {
    if (this.cobrowseManager.activeRoom && this.cobrowseManager.activeRoom.yourself) //if you are in a cobrowse session
    {
      const presentationSoData:IPresentationSoDataDto = {currentSlide: 0};
      this.cobrowseManager.activeRoom.registerSharedObject(SharedObjectType.PRESENTATION, presentationSoData);
    }
  }


  get presentationSO():SharedObject<IPresentationSoDataDto> | undefined
  {
    if (this.cobrowseManager.activeRoom)
    {
      return this.cobrowseManager.activeRoom.getSharedObject(SharedObjectType.PRESENTATION) as SharedObject<IPresentationSoDataDto>;
    }
    return undefined;
  }

  get currentSlide()
  {
    if (this.presentationSO)
    {
      return this.presentationSO.data.currentSlide;
    }
    return null;
  }

  @Watch('currentSlide', {immediate: true, deep: true})
  private async _onCurrentSlideChanged()
  {
    console.log("_onCurrentSlideChanged", this.currentSlide);
    const msg:any = {
      messageType : ApiAction.SLIDE,
      messageValue: this.currentSlide
    };
    const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
    if (playerFrame && playerFrame.contentWindow)
    {
      playerFrame.contentWindow.postMessage(msg, '*');
    }
  }



  public async checkNeedForSaveAndExit()
  {
    if (this.engineMode === EngineMode.PRESENTATION_EDITOR || this.engineMode === EngineMode.SLIDE_EDITOR)
    {
      const msg:IEngineMessageDto = {
        messageType: EngineMessageType.SAVE_AND_EXIT
      };
      const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
      if (playerFrame && playerFrame.contentWindow)
      {
        playerFrame.contentWindow.postMessage(msg, '*');
      }
      else
      {
        this._exitPlayer();
      }
    }
    else
    {
      this._exitPlayer();
    }
  }

  @Watch('engineMode', {immediate: true, deep: false})
  @Watch('presentationID', {immediate: true, deep: false})
  @Watch('audienceSessionIdentifier', {immediate: true, deep: false})
  private async _setPlayerUrl()
  {
    const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;

    if (playerFrame)
    {
      const authToken:string = await appUserController.getAuthToken() as string;

      const appUser:AppUserModel = AppUserModel.getInstance();
      const storageUrl:string = appUser.project.storageUrl;
      let construct:string = "default";
      if (this.presentation)
      {
        construct = this.presentation.construct;
      }
      else if (this.useConstruct)
      {
        construct = this.useConstruct;
      }
      else if (this.slide)
      {
        //todo ugly hack (because PieterB didn't give the default construct the name "default")
        if (this.slide.originalConstruct !== "constructNespresso")
        {
          construct = this.slide.originalConstruct;
        }
      }

      let url:string;
      if (process.env.VUE_APP_DEV_STORAGE_URL && process.env.NODE_ENV === "development")
      {
        url = `${process.env.VUE_APP_DEV_STORAGE_URL}${appUser.project.identifier}/constructs/${construct}/index.html?storageurl=${appUser.project.storageUrl}&uselocalmodules&`
      }
      else
      {
        url = `${storageUrl}/constructs/${construct}/index.html?`
      }
      url += `engmode=${this.engineMode}&envmode=APP&authtoken=${authToken}&userid=${appUser.ID}&apiurl=${appUser.project.apiUrl}`;

      if (this.engineMode === EngineMode.PRESENT || this.engineMode === EngineMode.TRAIN)
      {
        const audienceID:string = this.audience ? this.audience.ID : "";
        url += `&presid=${this.presentationID}&audienceid=${audienceID}`;

        if (this.cobrowseManager.activeRoom && this.cobrowseManager.activeRoom.yourself) //if you are in a cobrowse session
        {
          url += `&iscobrowse=1`;
        }
      }
      else if (this.engineMode === EngineMode.PRESENTATION_EDITOR)
      {
        url += `&presid=${this.presentationID}`;
      }
      else if (this.engineMode === EngineMode.SLIDE_EDITOR)
      {
        url += `&slideid=${this.slideID}`;
      }
      else if (this.engineMode === EngineMode.SINGLE_SLIDE)
      {
        url += `&slideid=${this.slideID}`;
      }
      else if (this.engineMode === EngineMode.ANALYTICS)
      {
        url += `&asi=${this.audienceSessionIdentifier}`;
      }



      // console.log(url);
      playerFrame.src = url;
    }

  }

  private _onIframeLoad()
  {
    const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
    if (this.engineMode !== EngineMode.SINGLE_SLIDE)
    {
      // @ts-ignore;
      playerFrame.contentWindow.focus();
    }
    if (this.engineMode === EngineMode.PRESENTATION_EDITOR || this.engineMode === EngineMode.SLIDE_EDITOR)
    {
      this.exitAllowed = false;
    }
  }

  private async listenToEngine(p_messageEvent:MessageEvent)
  {
    //todo: check origin


    const message:IEngineMessageDto = p_messageEvent.data;
    if (message.messageType)
    {

      switch (message.messageType)
      {
        case  EngineMessageType.REGISTER_AUDIENCE_SESSION:
          audienceSessionController.registerAudienceSession(message.messageValue);
          break;

        case  EngineMessageType.REGISTER_AUDIENCE_SESSION_EVENT:
          audienceSessionController.registerAudienceSessionEvent(message.messageValue);
          break;

        case  EngineMessageType.ASK_SAVE:
          if (this.presentation)
          {
            contentController.saveBody(this.presentation, message.messageValue);
          }
          else if (this.slide)
          {
            slideController.saveBody(this.slide, message.messageValue);
          }
          break;

        case  EngineMessageType.ASK_SAVE_AND_EXIT:
          if (this.presentation || this.slide)
          {
            const saveConfirmed:boolean = await this.$bvModal.msgBoxConfirm("Do you want to save the changes you have made?",
                {
                  centered     : true,
                  cancelVariant: 'danger',
                  okTitle      : 'YES',
                  cancelTitle  : 'NO'
                });

            if (saveConfirmed)
            {

              if (this.presentation)
              {
                contentController.saveBody(this.presentation, message.messageValue);
              }
              else if (this.slide)
              {
                slideController.saveBody(this.slide, message.messageValue);
              }

            }
            this._exitPlayer();
          }
          break;

        case  EngineMessageType.REPORT_ERROR:
          appController.reportError(message.messageValue);
          break;

        case  EngineMessageType.ASK_EXIT:
          this._exitPlayer();
          break;

        case  EngineMessageType.ASK_DATAPROVIDER_PICKER:
          if (message.messageValue && AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_DATA_PROVIDERS.identifier) >= 0)
          {
            const dtpPickerDto:IDataProviderPickerDto = message.messageValue;
            this.selectedDtpUri = dtpPickerDto.selectedDtpUri;
            this.allowedSchema = dtpPickerDto.allowedSchema;
            this.$bvModal.show('dataprovider-picker-box');
          }
          break;

        case  EngineMessageType.ASK_LINK_WIZARD:
          if (message.messageValue)
          {
            this.linkWizardDto = message.messageValue;
            this.linkWizardDto!.allowOpenContent = this.linkWizardDto!.allowUrl = this.linkWizardDto!.allowNavigate = true;
            this.$bvModal.show('link-wizard-box');
          }
          break;

        case  EngineMessageType.ASK_ASSET_FILE_PICKER:
          if (message.messageValue)
          {
            this.fileAllowedTargetType = message.messageValue.librarySettings.allowedTargetTypes[0];
            this.fileAllowedFileTypes = message.messageValue.librarySettings.allowedFileTypes;
            this.selectedFileUri = message.messageValue.selectedItem;
            // console.log(this.fileAllowedTargetType , this.fileAllowedFileTypes, this.selectedFileUri);
            this.$bvModal.show('asset-picker-box');
          }
          break;

        case  EngineMessageType.ASK_EDIT_CONTAINER_PROPERTY:
          if (message.messageValue)
          {
            this.containerPropertyValueDto = message.messageValue;
            this.$bvModal.show('edit-container-property-box');
            // this._onContainerPropertyValueSet(message.messageValue.identifier, "toeooooooot")
          }
          break;

          //todo: WARNING: this code is duplicated in ContentAppPlayer.vue

        case  EngineMessageType.ASK_PRESENT_CONTENT:
          if (message.messageValue)
          {
            const content:ContentModel | null = ContentListModel.getInstance().getEntityByID(message.messageValue);
            if (!content)
            {
              this.$bvModal.msgBoxOk(this.$t('ContentNotAvailableToPresent') as string, {centered: true});
            }

            else
            {
              if (content.entityType === EntityType.CONTENT_FILE)
              {
                //todo: WARNING: this code is duplicated in MainPage.vue
                const fileType:FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension((content as ContentFileModel).fileUri));
                const fileTypeConfig:IFileTypeConfig = FILE_TYPE_CONFIG[fileType];
                const isMobileOnly = fileTypeConfig.isMobileOnly;

                if (isMobileOnly)
                {
                  if (!this.$device.ios && !this.$device.android)
                  {

                    this.$bvModal.msgBoxOk(this.$t('FileCanOnlyRunOnMobile') as string, {centered: true});
                    return;
                  }
                }
                const sessionIdentifier:string = audienceSessionController.createAudienceSession(content, this.audience!, SessionMode.PRESENT, ActivityType.FILE_SESSION, undefined, undefined, undefined, {fileType: fileType}, GamificationTargetIdentifier.PRESENT_CONTENT);

                if (fileTypeConfig.showInPlayer)
                {
                  const engineMode:EngineMode = EngineMode.PRESENT;
                  const contentFileID:string = content.ID;
                  this.$router.push({
                    name  : RoutingIdentifier.CONTENT_FILE_PLAYER,
                    params: {contentFileID: contentFileID, engineMode, sessionIdentifier}
                  });
                }
                else
                {
                  window.open(fileManager.getFileUrl((content as ContentFileModel).fileUri), "_blank");
                }
              }
              else
              {
                const contentID:string = message.messageValue;
                this.$router.push({
                  name: RoutingIdentifier.APP, params: {contentID}
                });
              }
            }
          }
          break;

        case  ApiAction.SLIDE as any:
          this.emitCobrowseEvent(message.messageType as any, message.messageValue);
          break;


        case  EngineMessageType.BROADCAST_MOUSE_POSITION:
          if (this.cobrowseManager.activeRoom && this.cobrowseManager.activeRoom.yourself) //if you are in a cobrowse session
          {
            this.cobrowseManager.activeRoom.yourself.userMouse = message.messageValue;
            this.cobrowseManager.activeRoom.roomUsersSO!.emitChanges();
          }
          break;
      }
    }
    else
    {
      return;
    }
  }


  private _beforeUnload(e:BeforeUnloadEvent)
  {

    // e.returnValue = "Are you sure you want to leave, changes you made will not be saved.";
    // return e.returnValue;
  }


  private _exitPlayer()
  {
    this.exitAllowed = true;

    const backRoute:string = this.slideID ? RoutingIdentifier.SLIDES : RoutingIdentifier.CONTENT_OVERVIEW;
    this.$router.push({name: backRoute});
  }

  private _onDtpPicked(p_dataProviderID:string)
  {
    this.$bvModal.hide('dataprovider-picker-box');
    if (p_dataProviderID)
    {
      const msg:IEngineMessageDto = {
        messageType : EngineMessageType.SET_DATAPROVIDER,
        messageValue: "dataProvider://" + p_dataProviderID
      };
      const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
      if (playerFrame && playerFrame.contentWindow)
      {
        playerFrame.contentWindow.postMessage(msg, '*');
      }
    }
  }

  private _onLinkChosen(p_linkValue:ILinkValueDto)
  {
    this.$bvModal.hide('link-wizard-box');
    if (p_linkValue)
    {
      const msg:IEngineMessageDto = {
        messageType : EngineMessageType.SET_LINK,
        messageValue: p_linkValue
      };
      const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
      if (playerFrame && playerFrame.contentWindow)
      {
        playerFrame.contentWindow.postMessage(msg, '*');
      }
    }
  }

  private _onAssetFilePicked(p_assetFile:AssetFileModel)
  {
    this.$bvModal.hide('asset-picker-box');
    if (p_assetFile)
    {
      const msg:IEngineMessageDto = {
        messageType : EngineMessageType.SET_ASSET_FILE,
        messageValue: p_assetFile.mapToDto(DtoType.BODY)
      };
      const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
      if (playerFrame && playerFrame.contentWindow)
      {
        playerFrame.contentWindow.postMessage(msg, '*');
      }
    }
  }

  private _onContainerPropertyValueSet(p_containerPropertyValueDto:IContainerPropertyValueDto)
  {
    this.$bvModal.hide('edit-container-property-box');
    if (p_containerPropertyValueDto)
    {

      const msg:IEngineMessageDto = {
        messageType : EngineMessageType.SET_CONTAINER_PROPERTY_VALUE,
        messageValue: p_containerPropertyValueDto
      };
      const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
      if (playerFrame && playerFrame.contentWindow)
      {
        playerFrame.contentWindow.postMessage(msg, '*');
      }
    }

  }

  private async _onBackButtonClick()
  {
    contentController.stopRemotePresent();
    const backRoute:string = this.slideID ? RoutingIdentifier.SLIDES : RoutingIdentifier.CONTENT_OVERVIEW;
    await this.$router.push({name: backRoute});
  }

  private emitCobrowseEvent(p_apiAction:ApiAction, p_value:any)
  {
    if (this.presentationSO && this.cobrowseManager.activeRoom && this.cobrowseManager.activeRoom.yourself && this.cobrowseManager.activeRoom.yourself.interactionState === CobrowseUserInteractionState.INTERACTIVE) //if you are in a cobrowse session
    {
      if (p_apiAction === ApiAction.SLIDE)
      {
        this.presentationSO.data.currentSlide = p_value;
      }
      this.presentationSO.emitChanges();
    }

  }

}
</script>
