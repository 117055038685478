<template>

    <div class="create-audience">

        <transition name="fade">
            <div v-if="currentFormState === formState.SEARCH_INPUT">
                {{ $t('ImportFrom') }}

                <div class="crmLogo" v-for="crmProvider in availableCrmProviders" :key="crmProvider"
                     @click="_selectProvider(crmProvider)">
                    <img class="crm__image" width="60px" :src="'img/crm/' + crmProvider + '.png'"/>
                </div>

                <div>
                    <small v-if="hasLatestImport"
                           v-html="$t('LatestImportSince', [currentCrmProvider, $d(new Date(latestImportDate), 'short')])"></small>
                    <hr>
                    <div>
                        {{ $t('ListContactsSince') }}
                        <date-picker v-model="fromDate"></date-picker>
                        <button class="btn btn-secondary mr-2" :disabled="!fromDate" @click="_doSearch(byType.DATE)">{{
                                $t('Go')
                            }}
                        </button>
                    </div>

                    <strong>{{ $t('Or') }}</strong>

                    <div>
                        {{ $t('SearchByName') }} <input type="text" v-model="searchInput">
                        <button class="btn btn-secondary mr-2" :disabled="searchInput.length < 2"
                                @click="_doSearch(byType.NAME)">{{ $t('Search') }}
                        </button>
                    </div>

                    <b-form-checkbox @input="_onOnlyMyOwnContactClick" v-model="onlyShowMyOwnContacts">
                        {{ $t('OnlyShowMyOwnContacts') }}
                    </b-form-checkbox>

                    <hr>

                    <div v-if="isSearching" class="text-center">
                        <b-spinner variant="dark" class="my-5"></b-spinner>
                    </div>
                    <div v-else>
                        <div v-if="this.contacts.length === 0">
                            {{ $t('NoContactsFound') }}
                        </div>
                        <div v-else>
                            <b-form-checkbox @input="_onSelectAllClick" v-model="allSelected"/>
                            <b-form-checkbox v-for="contact in this.contacts" :key="contact.remoteIdentifier"
                                             :value="contact.remoteIdentifier" @input="_onContactClick"
                                             v-model="selectedContacts" class="mr-3 mb-1">
                                {{ contact.displayName }} <small><i>{{ contact.companyName }}</i></small> <span v-if=" contact.isExisting ">({{ $t('ContactExists') }})</span>
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>

                <div v-if="contacts.length > 0" class="d-flex justify-content-end">
                    <b-form-checkbox v-model="alsoImportContactAccounts">
                        {{ $t('AlsoImportContactAccounts') }}
                    </b-form-checkbox>
                </div>

                <div class="d-flex justify-content-end">
                    <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
                    <button v-if="contacts.length > 0" class="btn btn-primary" :disabled="selectedContacts.length === 0"
                            @click="_onImportAudiencesBtnClick">
                        {{ $t('ImportSelectedContacts') }}
                    </button>
                </div>


            </div>
        </transition>


        <transition name="fade">
            <div v-if="currentFormState === formState.IMPORTING_AUDIENCES" class="text-center">
                <b-spinner variant="dark" class="my-5"></b-spinner>
                <div>{{ $t('ImportingContacts') }}</div>
                <button class="btn btn-primary" @click="_onCancelBtnClick">
                    {{ $t('Ok') }}
                </button>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.AUDIENCES_IMPORTED" class="text-center">
                {{ $t('ContactsImported') }}
                <button class="btn btn-primary" @click="_onCancelBtnClick">
                    {{ $t('Ok') }}
                </button>
            </div>
        </transition>


        <transition name="fade">
            <div v-if="currentFormState === formState.IMPORT_ERROR">
                <p class="mb-4 text-danger">{{ $t('AudienceImportFailed') }}</p>

            </div>
        </transition>

    </div>

</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {CrmProvider} from "@/crm/_model/crm.constants";
import CrmModel from "@/crm/_model/CrmModel";
import {ICrmContactDto, ICrmProviderDto, ICrmUserDto} from "@/crm/_model/crm.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DatePicker from "vue2-datepicker";
import crmController from "@/crm/_controller/CrmController";

enum FormState
{
    SEARCH_INPUT,
    IMPORTING_AUDIENCES,
    AUDIENCES_IMPORTED,
    IMPORT_ERROR
}

enum SearchByType
{
    DATE,
    NAME,
}

@Component({
    components: {DatePicker}
})
export default class ImportAudiencesBox extends Vue
{


    //---------------------------------
    // Validations
    //---------------------------------

    //---------------------------------
    // Vue Component props
    //---------------------------------

    //---------------------------------
    // Vue Component data
    //---------------------------------



    private formState:typeof FormState = FormState;
    private byType:typeof SearchByType = SearchByType;


    private currentFormState:FormState = FormState.SEARCH_INPUT;

    private currentCrmProvider:CrmProvider = CrmProvider.XLS_IMPORT;

    private latestImportDate?:Date;
    private hasLatestImport:boolean = false;
    private fromDate?:Date = new Date();
    private searchInput:string = "";
    private onlyShowMyOwnContacts:boolean = true;
    private isSearching:boolean = false;
    private latestSearchByType:SearchByType = SearchByType.DATE;
    private contacts:ICrmContactDto[] = [];
    private selectedContacts:string[] = [];
    private allSelected:boolean = true;
    private alsoImportContactAccounts:boolean = true;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------



    get availableCrmProviders():CrmProvider[]
    {
        return CrmModel.getInstance().getAvailableCrmProvidersForUser();
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted()
    {
        this._selectProvider(this.availableCrmProviders[0]);
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _selectProvider(p_crmProvider:CrmProvider)
    {
        this.currentCrmProvider = p_crmProvider;
        this.selectedContacts = [];
        this.contacts = [];

        const crmProviderDto:ICrmProviderDto = AppUserModel.getInstance().project.crmProviders[this.currentCrmProvider];
        const crmUserDto:ICrmUserDto = AppUserModel.getInstance().crmProviders[this.currentCrmProvider];
        if (crmUserDto && crmUserDto.latestImportDate)
        {
            this.fromDate = this.latestImportDate = new Date(crmUserDto.latestImportDate);
            this.hasLatestImport = true;
        }
        else
        {
            this.fromDate = new Date(Date.now() - 12096e5);
            this.latestImportDate = undefined;
            this.hasLatestImport = false;
        }
        this._doSearch(this.latestSearchByType);
    }

    private async _doSearch(p_byType:SearchByType)
    {
        this.latestSearchByType = p_byType;
        this.isSearching = true;
        this.contacts = [];
        if (p_byType === SearchByType.DATE)
        {
            this.searchInput = "";
            if (!this.fromDate)
            {
                this.fromDate = new Date(Date.now() - 12096e5);
            }
            this.contacts = await crmController.searchContacts(this.currentCrmProvider, this.onlyShowMyOwnContacts, undefined, this.fromDate);
        }
        else
        {
            this.fromDate = undefined;
            this.contacts = await crmController.searchContacts(this.currentCrmProvider, this.onlyShowMyOwnContacts, this.searchInput);
        }
        this.setSelection(true);
        this.isSearching = false;
    }

    private async _onImportAudiencesBtnClick(p_e:Event)
    {
        this.currentFormState = FormState.IMPORTING_AUDIENCES;
        const hasSucceeded:boolean = await crmController.doBackendImport(this.currentCrmProvider, this.selectedContacts, this.alsoImportContactAccounts);
        if (hasSucceeded)
        {
            this.currentFormState = FormState.AUDIENCES_IMPORTED;
        }
        else
        {
            this.currentFormState = FormState.IMPORT_ERROR;
        }
    }

    private async _onCancelBtnClick(p_e:Event)
    {
        this.$emit('onFinish', null);
    }

    private setSelection(p_selectAll:boolean = true)
    {
        const selectedContacts:string[] = [];
        if (p_selectAll)
        {
            for (let i = 0; i < this.contacts.length; i++)
            {
                selectedContacts.push(this.contacts[i].remoteIdentifier!)
            }
        }
        this.selectedContacts = selectedContacts;
        this.allSelected = p_selectAll;
    }

    private async _onOnlyMyOwnContactClick(p_e:Event)
    {
        if (this.fromDate || this.searchInput)
        {
            this._doSearch(this.latestSearchByType);
        }
    }

    private async _onSelectAllClick(p_e:Event)
    {
        this.setSelection(this.allSelected);
    }

    private async _onContactClick(p_e:Event)
    {
        if (this.selectedContacts.length === this.contacts.length)
        {
            this.allSelected = true;
        }
        else if (this.selectedContacts.length === 0)
        {
            this.allSelected = false;
        }
    }

}
</script>

<style>

.crmLogo {
    display: inline-block;
}
</style>
