import i18n from '@/__plugins/i18n';
import {IDeepLinkParamsDto, IGlobalDto, ISplashDto} from "@/_model/app.dto";
import {ServiceWorkerState} from "@/_model/app.constants";

class AppModel {

    public global!: IGlobalDto;
    public serverTime?: Date;
    public splash: ISplashDto = {
        bgUrl: 'img/init/init-bg.jpg',
        logoUrl: 'img/init/init-logo.png',
        welcomeMessage: i18n.t('InitWelcome') as string,
        aaUserNotFoundMessage: i18n.t('InitErrorCredentials') as string
    };

    public deepLinkParams: IDeepLinkParamsDto | null = null;

    public version: string | undefined = process.env.VERSION;

    // public updateAvailable: boolean = false;
    // public updateSkipped: boolean = false;

    private static _instance: AppModel;

    constructor() {
        if (!AppModel._instance) {
            AppModel._instance = this;
        }
    }

    public static getInstance(): AppModel {
        if (!AppModel._instance) {
            new AppModel();
        }

        return AppModel._instance;
    }

}

export default AppModel;
