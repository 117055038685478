<template>
    <div class="player content-app-player">
        <iframe class="player__frame" ref="playerFrame" v-on:load="_onIframeLoad()"></iframe>

        <div class="player__back" @click="_onBackButtonClick">
            <div class="player__back__background"></div>
            <i class="fas fa-chevron-left"></i>
        </div>

        <b-modal id="cap-share-with-audience-box" :title="$t('DashboardShowShareTitle', [audience.displayName])"
                 hide-footer
                 v-if="contentApp && audience">
            <ShareWithAudienceBox :content="contentApp" :audience="audience" :shareMessage="shareMessage"
                                  :shareWithMultiple="false"
                                  v-on:onFinish="_onShareWithAudienceFinish"></ShareWithAudienceBox>
        </b-modal>
    </div>
</template>


<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import ContentListModel from "@/content/_model/ContentListModel";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {EngineMessageType, EngineMode} from "@/presentation/player/_model/player.constants";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import {IEngineMessageDto} from "@/presentation/player/_model/player.dto";
import audienceController from "@/audience/_controller/AudienceController";
import audienceSessionController from "@/audience/session/_controller/AudienceSessionController";
import {IAudienceSessionDto, IAudienceSessionEventDto} from "@/audience/session/_model/audience_session.dto";
import contentController from "@/content/_controller/ContentController";
import ShareWithAudienceBox from "@/content/_view/ShareWithAudienceBox.vue";
import appUserController from "@/project/user/_controller/AppUserController";
import {RoutingIdentifier} from "@/router";
import ContentModel from "@/content/_model/ContentModel";
import {EntityType} from "@/entity/_model/entity.constants";
import {FILE_TYPE_CONFIG, FileType, IFileTypeConfig} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import {SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import fileManager from "@/_controller/FileManager";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";
import AppModel from "@/_model/AppModel";
import EntityModel from "@/entity/_model/EntityModel";


@Component({
        components: {ShareWithAudienceBox}
    })
    export default class ContentAppPlayer extends Vue
    {

        @Prop() private engineMode!:EngineMode;
        @Prop() private appID!:string;
        @Prop() private sessionIdentifier!:string;

        private shareMessage:string = "";


        get audience():AudienceModel | null
        {
            return AudienceListModel.getInstance().globalSelState.selected;
        }

        get contentApp():ContentAppModel | null
        {
            if (this.appID)
            {
                return ContentListModel.getInstance().getEntityByID(this.appID) as ContentAppModel;
            }
            return null;
        }

        public mounted()
        {
            window.addEventListener('message', this.listenToEngine);
            window.addEventListener('beforeunload', this._beforeUnload);
            this._setPlayerUrl();
        }

        public beforeDestroy()
        {
            window.removeEventListener('message', this.listenToEngine);
            window.removeEventListener('beforeunload', this._beforeUnload);
        }

        @Watch('appID', {immediate: true, deep: false})
        @Watch('audience', {immediate: true, deep: false})
        private async _setPlayerUrl()
        {

            const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;

            if (playerFrame)
            {
                const authToken:string = await appUserController.getAuthToken() as string;

                const appUser:AppUserModel = AppUserModel.getInstance();
                const storageUrl:string = appUser.project.storageUrl;
                const resourceIdentifier:string = this.contentApp ? this.contentApp.resourceIdentifier : "defaultApp";

                let url:string;
                if (process.env.VUE_APP_DEV_STORAGE_URL && process.env.NODE_ENV === "development")
                {
                    url = `${process.env.VUE_APP_DEV_STORAGE_URL}${appUser.project.identifier}/contentAppResources/${resourceIdentifier}/index.html?storageurl=${appUser.project.storageUrl}&`
                }
                else
                {
                    url = `${storageUrl}/contentAppResources/${resourceIdentifier}/index.html?`
                }
                url += `engmode=${this.engineMode}&envmode=APP&appid=${this.appID}&authtoken=${authToken}&userid=${appUser.ID}&apiurl=${appUser.project.apiUrl}`;

                if (this.engineMode === EngineMode.PRESENT || this.engineMode === EngineMode.PREPARE  || this.engineMode === EngineMode.ANALYTICS)
                {
                    const audienceID:string = this.audience ? this.audience.ID : "";
                    url += `&audienceid=${audienceID}`;
                }
                if (this.engineMode === EngineMode.ANALYTICS )
                {
                  url += `&sessionidentifier=${this.sessionIdentifier}`;
                }
                if (appUser.project.config.hasCobrowse)
                {
                    url += `&cobrowseurl=${AppModel.getInstance().global.cobrowseUrl}`;
                }
                playerFrame.src = url;
            }

        }

        private _onIframeLoad()
        {
            const playerFrame:HTMLIFrameElement = this.$refs.playerFrame as HTMLIFrameElement;
            // @ts-ignore;
            playerFrame.contentWindow.focus();
        }

        private async listenToEngine(p_messageEvent:MessageEvent)
        {
            //todo: check origin

            const message:IEngineMessageDto = p_messageEvent.data;

            if (message.messageType)
            {

                switch (message.messageType)
                {
                    case  EngineMessageType.ASK_PATCH_AUDIENCE:
                        audienceController.patchBody(this.audience!, message.messageValue);
                        break;

                    case  EngineMessageType.REGISTER_AUDIENCE_SESSION:
                        if(!(message.messageValue as IAudienceSessionDto).parentIdentifier && this.sessionIdentifier)
                        {
                          (message.messageValue as IAudienceSessionDto).parentIdentifier = this.sessionIdentifier;
                        }
                        audienceSessionController.createAudienceSession(
                            this.contentApp as EntityModel,
                            this.audience!,
                            SessionMode.PRESENT,
                            (message.messageValue as IAudienceSessionDto).activityType,
                            (message.messageValue as IAudienceSessionDto).subjectIdentifier,
                            (message.messageValue as IAudienceSessionDto).length,
                            (message.messageValue as IAudienceSessionDto).parentIdentifier,
                            (message.messageValue as IAudienceSessionDto).payload,
                            (message.messageValue as IAudienceSessionDto).gamificationTargetIdentifier,
                            (message.messageValue as IAudienceSessionDto).identifier,
                        );
                        break;

                    case  EngineMessageType.REGISTER_AUDIENCE_SESSION_EVENT:
                        (message.messageValue as IAudienceSessionEventDto).audienceID = this.audience!.ID;
                        (message.messageValue as IAudienceSessionEventDto).parentSessionIdentifier = this.sessionIdentifier;
                        audienceSessionController.registerAudienceSessionEvent(message.messageValue);
                        break;

                    case  EngineMessageType.ASK_SAVE:
                        if (this.contentApp)
                        {
                            contentController.saveBody(this.contentApp, message.messageValue);
                        }
                        break;

                    case  EngineMessageType.ASK_SHARE_WITH_AUDIENCE:
                        if (this.contentApp)
                        {
                            this.shareMessage = message.messageValue.shareMessage;
                            this.$bvModal.show('cap-share-with-audience-box');
                        }
                        break;
                    //todo: WARNING: this code is duplicated in PresentationPlayer.vue
                    case  EngineMessageType.ASK_PRESENT_CONTENT:
                        if (message.messageValue)
                        {
                            const content:ContentModel | null = ContentListModel.getInstance().getEntityByID(message.messageValue);
                            if (!content)
                            {
                                this.$bvModal.msgBoxOk(this.$t('ContentNotAvailableToPresent') as string, {centered: true});
                            }

                            else
                            {
                                if (content.entityType === EntityType.CONTENT_FILE)
                                {
                                    //todo: WARNING: this code is duplicated in MainPage.vue
                                    const fileType:FileType = AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension((content as ContentFileModel).fileUri));
                                    const fileTypeConfig:IFileTypeConfig = FILE_TYPE_CONFIG[fileType];
                                    const isMobileOnly = fileTypeConfig.isMobileOnly;

                                    if (isMobileOnly)
                                    {
                                        if (!this.$device.ios && !this.$device.android)
                                        {

                                            this.$bvModal.msgBoxOk(this.$t('FileCanOnlyRunOnMobile') as string, {centered: true});
                                            return;
                                        }
                                    }
                                    const sessionIdentifier:string = audienceSessionController.createAudienceSession(content, this.audience!, SessionMode.PRESENT, ActivityType.FILE_SESSION, undefined, undefined, undefined, {fileType:fileType}, GamificationTargetIdentifier.PRESENT_CONTENT);

                                    if (fileTypeConfig.showInPlayer)
                                    {
                                        const engineMode:EngineMode = EngineMode.PRESENT;
                                        const contentFileID:string = content.ID;
                                        this.$router.push({
                                            name  : RoutingIdentifier.CONTENT_FILE_PLAYER,
                                            params: {contentFileID: contentFileID, engineMode, sessionIdentifier}
                                        });
                                    }
                                    else
                                    {
                                        window.open(fileManager.getFileUrl((content as ContentFileModel).fileUri), "_blank");
                                    }
                                }
                                else
                                {
                                    const contentID:string = message.messageValue;
                                    this.$router.push({
                                        name: RoutingIdentifier.MAIN, params: {contentID}
                                    });
                                }
                            }
                        }
                        break;
                }
            }
            else
            {
                return;
            }
        }

        private _beforeUnload(e:BeforeUnloadEvent)
        {

            // e.returnValue = "Are you sure you want to leave, changes you made will not be saved.";
            // return e.returnValue;
        }

        private _onShareWithAudienceFinish()
        {
            this.$bvModal.hide('cap-share-with-audience-box');
        }

        private async _onBackButtonClick()
        {
            contentController.stopRemotePresent();
            await this.$router.push({name: RoutingIdentifier.CONTENT_OVERVIEW});
        }

    }
</script>
