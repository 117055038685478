<template>
  <div class="property" :class="`property--${property.propertyDefinition.type}`" @mouseover="isHovered = true"
       @mouseleave="isHovered = false">

    <div class="property__name" :class="{ 'text-danger': !property.isValid }">
      <div class="property__name__content">
        <span class="property__name__content__text">{{ name }}:</span>
        <span class="property__name__content__required" v-if="property.propertyDefinition.isRequired">*</span>
        <b-badge
            v-if="!property.isValid && !property.propertyDefinition.isPrimitive && property.propertyDefinition.type !== 'SELECT_LIST'"
            variant="danger" @click="showInvalidInfo">{{ property.getNrOfInvalidChildren() }}
        </b-badge>
        <b-badge variant="warning" v-if="property.shownFallBackLangCode" v-b-tooltip.hover
                 :title="$t('ValueNotProvidedInActiveLanguage')">{{ property.shownFallBackLangCode }}
        </b-badge>
      </div>
      <div class="property__name__error-messages small" v-if="!property.isValid">
        {{ invalidLabel }} <span v-if="property.inValidLanguages.length > 0">({{
          property.inValidLanguages.join(", ")
        }})</span>
      </div>
      <div v-if="description" class="property__name__description font-italic small">
        {{ description }}
      </div>
    </div>


    <transition name="fade">

      <div class="property__value">

        <div v-if="prefix" class="property__value__prefix small">{{ prefix }}</div>

        <!--  primitive types-->

        <input v-if="property.propertyDefinition.formElement === componentType.TEXT_INPUT"
               :maxlength="property.propertyDefinition.maxLength"
               type="text" v-model="property.displayedValue" class="form-control"
               :disabled="!property.isEditable">

        <textarea v-if="property.propertyDefinition.formElement === componentType.TEXT_AREA"
                  :maxlength="property.propertyDefinition.maxLength"
                  v-model="property.displayedValue" class="form-control"
                  :disabled="!property.isEditable"></textarea>

        <rich-text-editor v-if="property.propertyDefinition.formElement === componentType.RICH_TEXT"
                          :property="property" />

        <input v-if="property.propertyDefinition.formElement === componentType.NUMBER_INPUT"
               type="number" v-model="property.displayedValue" :disabled="!property.isEditable"
               :step="property.propertyDefinition.step" :min="property.propertyDefinition.minimum"
               :max="property.propertyDefinition.maximum" class="form-control">

        <div class="slider" v-if="property.propertyDefinition.formElement === componentType.SLIDER">
          <div class="slider__value mb-1 text-center">{{ property.displayedValue }}</div>
          <div class="slider__bar">
            <!--            <div class="slider__bar__range slider__bar__range&#45;&#45;min">{{ property.propertyDefinition.minimum }}</div>-->
            <input
                type="range" v-model="property.displayedValue" :disabled="!property.isEditable"
                :step="property.propertyDefinition.step" :min="property.propertyDefinition.minimum"
                :max="property.propertyDefinition.maximum" class="form-control-range">
            <!--            <div class="slider__bar__range slider__bar__range&#45;&#45;max">{{ property.propertyDefinition.maximum }}</div>-->
          </div>
        </div>

        <input v-if="property.propertyDefinition.formElement === componentType.DATE_PICKER"
               type="date" v-model="property.displayedValue" :disabled="!property.isEditable"
               :step="property.propertyDefinition.step" :min="property.propertyDefinition.minDate"
               :max="property.propertyDefinition.maxDate" class="form-control">

        <b-form-checkbox v-if="property.propertyDefinition.formElement === componentType.CHECKBOX"
                         v-model="property.displayedValue" :disabled="!property.isEditable" size="lg"/>

        <input v-if="property.propertyDefinition.formElement === componentType.COLOR_PICKER"
               type="color" v-model="property.displayedValue" :disabled="!property.isEditable"
               class="form-control">

        <div class="asset-file"
             v-if="property.propertyDefinition.formElement === componentType.ASSET_FILE_INPUT"
             @click="onOpenAssetPickerBtnClick">

          <div v-if="!property.displayedValue" class="btn btn-secondary btn-block btn-sm">
            {{ $t('PickFileOrUploadNewOne') }}
          </div>

          <div v-else>
            <AAImage v-if="assetFileType === fileType.IMAGE" :imageUrl="assetFileUrl"/>
            <video v-else-if="assetFileType === fileType.VIDEO" :src="assetFileUrl" controls></video>
            <AAImage v-else :imageUrl="defaultPreviewImageUrl"/>
          </div>

          <b-modal :id="property.propertyDefinition.identifier" size="xl"
                   :title="$t('PickFileOrUploadNewOne')" hide-footer>
            <AssetFoldersPicker :selectedFileUri="property.displayedValue"
                                :allowedTargetType="property.propertyDefinition.allowedTargetType"
                                :allowedFileTypes="property.propertyDefinition.allowedFileTypes"
                                v-on:onAssetFilePicked="onAssetFilePicked"></AssetFoldersPicker>
          </b-modal>
        </div>

        <FixedList v-if="property.propertyDefinition.type === 'FIXED_LIST'" :property="property"/>

        <!-- NON   primitive types-->

        <DataItem v-if="property.propertyDefinition.type === 'SINGLE_ITEM'"
                  :dataItem="property.displayedValue"/>

        <DataItemList v-if="property.propertyDefinition.type === 'ITEM_LIST'"
                      :dataItemList="property.displayedValue"/>

        <DataItemDictionary v-if="property.propertyDefinition.type === 'ITEM_DICTIONARY'"
                            :dataItemDictionary="property.displayedValue" :property="property"/>

        <DataItemSelectList v-if="property.propertyDefinition.type === 'SELECT_LIST'"
                            :selectList="property.displayedValue" :property="property"/>

        <DataProviderPicker v-if="property.propertyDefinition.type === 'DATAPROVIDER_LINK'"
                            :dataItemProviderLinkModel="property.displayedValue" :property="property"/>

        <ContentPicker v-if="property.propertyDefinition.type === 'CONTENT_LINK'"
                       :dataItemContentLinkModel="property.displayedValue" :property="property"/>

        <div v-if="suffix" class="property__value__suffix small">{{ suffix }}</div>

        <transition name="fade">
          <div v-if="showClear" class="property__erase" @click="clearValue">
            <i class="fa-solid fa-xmark-circle"></i>
          </div>
        </transition>
      </div>

    </transition>


  </div>
</template>


<script lang="ts">
import "@/__plugins/quill-extensions";
import {Component, Prop, Vue} from "vue-property-decorator";
import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
import {FormElement, InValidType} from "@/data_tool/_model/data_tool.constants";
import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import fileManager from "@/_controller/FileManager";
import {FileType} from "@/asset_folder/_model/asset_folder.constants";
import AssetFolderListModel from "@/asset_folder/_model/AssetFolderListModel";
import FileUtil from "@/__libs/utility/FileUtil";
import AAImage from "@/_view/components/AAImage.vue";
import RichTextEditor from '@/data_tool/data_item/_view/RichTextEditor.vue';

@Component({
    components: {
      RichTextEditor,
      AAImage,
      DataItemSelectList: () => import('@/data_tool/data_item/_view/DataItemSelectList.vue'),
      FixedList: () => import('@/data_tool/data_item/_view/FixedList.vue'),
      DataItem: () => import('@/data_tool/data_item/_view/DataItem.vue'),
      DataItemList: () => import('@/data_tool/data_item/_view/DataItemList.vue'),
      DataItemDictionary: () => import('@/data_tool/data_item/_view/DataItemDictionary.vue'),
      DataProviderPicker: () => import('@/data_tool/data_item/_view/DataProviderPicker.vue'),
      ContentPicker: () => import('@/data_tool/data_item/_view/ContentPicker.vue'),
      AssetFoldersPicker
    }
})
export default class Property extends Vue {
    //---------------------------------
    // Vue Component props
    //---------------------------------
    @Prop() public property!: PropertyModel;

    //---------------------------------
    // Vue Component data
    //---------------------------------
    public componentType: typeof FormElement = FormElement;
    public fileType: typeof FileType = FileType;

    public isHovered: boolean = false;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------
    get name() {
        let name: string = languageManager.getTranslationForValue<string>(this.property.propertyDefinition.name as IMultiLangString, this.property.dataProvider.activeLangCode);
        if (this.property.propertyDefinition.isMultiLingual) {
            name += ` (${this.property.dataProvider.activeLangCode})`;
        }
        return name;
    }

    get description(): string | null {
        if (this.property.propertyDefinition.description) {
            return languageManager.getTranslationForValue<string>(this.property.propertyDefinition.description as IMultiLangString, this.property.dataProvider.activeLangCode);
        }
        return null;
    }

    get prefix(): string | null {
        if (this.property.propertyDefinition.prefix) {
            return languageManager.getTranslationForValue<string>(this.property.propertyDefinition.prefix as IMultiLangString, this.property.dataProvider.activeLangCode);
        }
        return null;
    }

    get suffix(): string | null {
        if (this.property.propertyDefinition.suffix) {
            return languageManager.getTranslationForValue<string>(this.property.propertyDefinition.suffix as IMultiLangString, this.property.dataProvider.activeLangCode);
        }
        return null;
    }

    get assetFileUrl() {
        return fileManager.getFileUrl(this.property.displayedValue);
    }

    get assetFileType() {
        return AssetFolderListModel.getFileTypeForExtension(FileUtil.getFileExtension(this.property.displayedValue));
    }

    get defaultPreviewImageUrl() {
        return fileManager.getFileUrl(AssetFolderListModel.getDefaultPreviewImageUriForFileType(this.assetFileType));
    }

    get invalidLabel(): string {
        switch (this.property.inValidType) {
            case InValidType.FILL_IN_MIN_CHARS:
                return this.$t(this.property.inValidType as string, [this.property.propertyDefinition.minLength]) as string;

            case InValidType.FILL_IN_MAX_CHARS:
                return this.$t(this.property.inValidType as string, [this.property.propertyDefinition.maxLength]) as string;

            case InValidType.MAKE_MAX_SELECTION:
                return this.$t(this.property.inValidType as string, [this.property.propertyDefinition.maxItems]) as string;

            case InValidType.MAKE_MIN_SELECTION:
                return this.$t(this.property.inValidType as string, [this.property.propertyDefinition.minItems]) as string;

            case InValidType.CREATE_MIN_ITEMS:
                return this.$t(this.property.inValidType as string, [this.property.propertyDefinition.minItems]) as string;

            case InValidType.CREATE_MAX_ITEMS:
                return this.$t(this.property.inValidType as string, [this.property.propertyDefinition.maxItems]) as string;

            default:
                return this.$t(this.property.inValidType as string) as string;
        }
    }

    get showClear() {
        return this.property.propertyDefinition.isPrimitive && this.isHovered && this.property.displayedValue;
    }

    public onOpenAssetPickerBtnClick(p_e: Event) {
        if (this.property.isEditable) {
            this.$bvModal.show(this.property.propertyDefinition.identifier);
        }
    }

    public onAssetFilePicked(p_assetFile: AssetFileModel) {
        this.$bvModal.hide(this.property.propertyDefinition.identifier);
        if (p_assetFile) {
            this.property.displayedValue = p_assetFile.fileURI;
        }
    }

    public showInvalidInfo(p_e: Event) {
        this.property.logInvalidChildren();
    }

    public async clearValue(p_e: Event) {
        const clearConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t("SureClearValue") as string, {centered: true});
        if (clearConfirmed) {
            this.property.clearValue();
        }
    }
}
</script>
