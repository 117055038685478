<template>

  <div class="team-users-detail">

    <div class="box box--full-height box--scrollable team-users-detail-primary">

      <transition name="fade">
        <div v-if="!selState.selected"
             class="empty-message">
          {{ $t('PleaseSelectATeamUser') }}
        </div>
      </transition>

      <transition name="fade">
        <div v-if="teamUser && !bodyLoaded"
             class="empty-loader">
          <b-spinner variant="dark"></b-spinner>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="teamUser && bodyLoaded" :key="`${teamUser.userID}_primary`" class="box-wrapper">
          <div class="d-flex flex-column align-items-center text-center">
            <AAProfilePicture :name="teamUser.displayName"
                              :imageUri="teamUser.avatarFileUri"
                              :editable="true"
                              :big="true"
                              class="my-3"
                              v-on:click.native="_onOpenAssetPickerBtnClick"></AAProfilePicture>
            <h4 class="mb-1 title">{{ teamUser.displayName }}</h4>
            <h6 class="mb-5">{{ teamUser.email }}</h6>
          </div>

          <b-form-group>
            <b-form-input size="lg"
                          :disabled="!isEditable"
                          v-model="validationTeamUser.displayName"
                          :placeholder="$t('PlaceholderDisplayName')"
                          :state="_calculateInputState($v.validationTeamUser.displayName)"
                          @input="teamUser.displayName = _onFormValidationInput($v.validationTeamUser.displayName, teamUser.displayName)"
                          tabindex="1"></b-form-input>
            <b-form-invalid-feedback>
              <span class="d-block"
                    v-if="!$v.validationTeamUser.displayName.required">{{
                  $t('FieldRequiredSimple')
                }}</span>
              <span class="d-block"
                    v-if="!$v.validationTeamUser.displayName.minLength">{{ $t('MinimumFieldLengthSimple', [3]) }}</span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.firstName" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderFirstName')"
                          @input="_onFormInput"
                          tabindex="2"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.lastName" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderLastName')"
                          @input="_onFormInput"
                          tabindex="3"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-select v-model="teamUser.langCode"
                           :disabled="!isEditable"
                           @change="_onFormInput" tabindex="4">
              <option v-for="langCode in availableLanguages" :key="langCode"
                      :value="langCode">{{ langCode }}
              </option>
            </b-form-select>
          </b-form-group>

          <div class="sub-box team-users-detail__box mb-3">

            <b-form-group>
              <label>
                <i class="fas fa-users"></i>
                {{ $t('TeamUserTeam') }}
              </label>
              <b-form-select v-model="teamUser.teamID"
                             :disabled="!isEditable"
                             @change="_onFormInput" tabindex="5">
                <option v-for="team in availableTeams" :key="team.teamID"
                        :value="team.teamID">{{ team.displayName }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group>
              <label>
                <i class="fas fa-chess"></i>
                {{ $t('TeamUserRole') }}
              </label>
              <b-form-select v-model="teamUser.roleID"
                             :disabled="!isRoleEditable"
                             @change="_onFormInput" tabindex="6">
                <option v-for="role in availableRoles" :key="role.roleID"
                        :value="role.roleID">{{
                    $t(`RoleName_${role.identifier}`)
                  }} ({{
                    $t(`RoleDescription_${role.identifier}`)
                  }})
                </option>
              </b-form-select>
            </b-form-group>

          </div>

          <b-form-group>
            <b-form-input v-model="teamUser.telNr" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderTelNr')" @input="_onFormInput"
                          tabindex="6"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.mobileNr" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderMobileNr')"
                          @input="_onFormInput"
                          tabindex="7"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.faxNr" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderFaxNr')" @input="_onFormInput"
                          tabindex="8"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.department" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderDepartment')"
                          @input="_onFormInput"
                          tabindex="9"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.jobTitle" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderJobTitle')"
                          @input="_onFormInput"
                          tabindex="10"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.address" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderAddress')" @input="_onFormInput"
                          tabindex="11"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.website" :disabled="!isEditable"
                          :placeholder="$t('PlaceholderWebsite')" @input="_onFormInput"
                          tabindex="12"></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input v-model="teamUser.signature"
                          :disabled="!isEditable"
                          :placeholder="$t('PlaceholderSignature')"
                          @input="_onFormInput"
                          tabindex="13"></b-form-input>
          </b-form-group>

        </div>
      </transition>

    </div>


    <div class="box box--full-height box--scrollable team-users-detail-secondary">

      <transition name="fade">
        <div v-if="!selState.selected"
             class="empty-message">
          {{ $t('PleaseSelectATeamUser') }}
        </div>
      </transition>

      <transition name="fade">
        <div v-if="teamUser && !bodyLoaded"
             class="empty-loader">
          <b-spinner variant="dark"></b-spinner>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="teamUser && bodyLoaded" :key="`${teamUser.userID}_secondary`" class="box-wrapper">

          <h4 class="title mt-1 mb-4">{{ $t('UserDetails') }}</h4>

          <table class="table table-sm table-striped table-borderless mb-4">
            <tbody>
            <tr>
              <th scope="row">{{ $t('UserStatus') }}</th>
              <td>{{ $t(`UserStatus_${teamUser.status}`) }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('CreatedDate') }}</th>
              <td>{{ $d(new Date(teamUser.createdDate), 'short') }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('UserOrigin') }}</th>
              <td>{{ $t(`UserOrigin_${teamUser.userOrigin}`) }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('LastLogin') }}</th>
              <td>
                <span v-if="teamUser.stats.lastLogin">
                    {{ $d(new Date(teamUser.stats.lastLogin), 'short') }}
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">{{ $t('NrOfPresentationsCreated') }}</th>
              <td>{{ teamUser.stats.nrOfPresentations }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('NrOfFilesCreated') }}</th>
              <td>{{ teamUser.stats.nrOfFiles }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('NrOfAudiencesCreated') }}</th>
              <td>{{ teamUser.stats.nrOfAudiences }}</td>
            </tr>
            <tr>
              <th scope="row">{{ $t('NrOfActiveShares') }}</th>
              <td>{{ teamUser.stats.nrOfShares }}</td>
            </tr>
            </tbody>
          </table>

          <b-button v-if="allowImpersonateUser" variant="outline-secondary" block
                  @click="_onImpersonateUserBtnClick">
            {{ $t('ImpersonateUser') }}
          </b-button>
          <b-button v-if="allowTransferUser" variant="outline-secondary" block
                  @click="_onTransferUserBtnClick">
            {{ $t('TransferUser') }}
          </b-button>
          <b-button v-if="isEditable" variant="outline-secondary" block
                  @click="_onDeleteUserBtnClick">
            {{ $t('DashboardShowDeleteButton') }}
          </b-button>

        </div>
      </transition>

    </div>

    <b-modal v-if="teamUser" id="app-user-asset-picker-box" size="xl" :title="$t('PickUserAvatarOrUploadNewOne')"
             hide-footer>
      <AssetFoldersPicker :selectedFileUri="teamUser.avatarFileUri"
                          :allowedTargetType="avatarAllowedTargetType"
                          :allowedFileTypes="avatarAllowedFileTypes"
                          v-on:onAssetFilePicked="_onAssetFilePicked"></AssetFoldersPicker>
    </b-modal>

    <b-modal id="user-transfer-box" size="s" :title="$t('TransferUser')" hide-footer>
      <UserTransferBox v-on:onUserTransfered="_onUserTransfered" :teamUser="teamUser"></UserTransferBox>
    </b-modal>

  </div>

</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import languageManager from "@/__libs/language_manager/LanguageManager";
import AssetFileModel from "@/asset_folder/_model/AssetFileModel";
import AssetFoldersPicker from "@/asset_folder/_view/AssetFoldersPicker.vue";
import {FileTargetType, FileType} from "@/asset_folder/_model/asset_folder.constants";
import SyncWidget from "@/sync/_view/SyncView.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import {minLength, required} from "vuelidate/lib/validators";

import SelectionState from "@/__libs/_model/SelectionState";
import TeamUserModel from "@/team/_model/TeamUserModel";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {IRoleOutlineDto, ITeamOutlineDto} from "@/team/_model/team.dto";
import RoleListModel from "@/team/_model/RoleListModel";
import teamUserController from "@/team/_controller/TeamUserController";
import {RIGHTS} from "@/team/_model/role.constants";
import {UserStatus} from "@/team/_model/team.constants";
import RoleModel from "@/team/_model/RoleModel";
import UserTransferBox from "@/team/_view/UserTransferBox.vue";
import {ILinkValueDto} from "@/presentation/_model/presentation.dto";
import {IEngineMessageDto} from "@/presentation/player/_model/player.dto";
import {EngineMessageType} from "@/presentation/player/_model/player.constants";


@Component({
  components: {
    AAProfilePicture, AssetFoldersPicker, SyncWidget, UserTransferBox
  },
  validations: {
    validationTeamUser: {
      displayName: {
        required,
        minLength: minLength(3)
      }
    }
  }
})
export default class TeamUserDetail extends Vue {

  //---------------------------------
  // Vue Component props
  //---------------------------------

  @Prop() private selState!: SelectionState<TeamUserModel>;

  //---------------------------------
  // Vue Component data
  //---------------------------------

  public avatarLoaded: Boolean = false;

  public avatarAllowedTargetType: FileTargetType = FileTargetType.USER_AVATAR;
  public avatarAllowedFileTypes: FileType[] = [FileType.IMAGE];

  private validationTeamUser: TeamUserModel = new TeamUserModel();


  //---------------------------------
  // Vue Computed properties
  //---------------------------------

  get availableLanguages(): string[] {
    return languageManager.availableLangCodes;
  }

  get availableTeams(): ITeamOutlineDto[] {
    return AppUserModel.getInstance().shareableTeams;
  }


  get availableRoles(): IRoleOutlineDto[] {
    return RoleListModel.getInstance().list;
  }

  get hasChanges() {
    return this.teamUser && this.teamUser.hasChanges;
  }

  get bodyLoaded() {
    if (this.teamUser) {
      return this.teamUser.loadingStatus >= LoadingStatus.BODY_LOADED;
    }
    return false;
  }

  get teamUser() {
    return this.selState.selected;
  }

  get allowImpersonateUser(): boolean {
    return ((AppUserModel.getInstance().rights.indexOf(RIGHTS.IMPERSONATE_USERS.identifier) >= 0) && (this.teamUser && this.teamUser!.status === UserStatus.VERIFIED)) as boolean;
  }

  get allowTransferUser(): boolean {
    return ((AppUserModel.getInstance().rights.indexOf(RIGHTS.TRANSFER_USERS.identifier) >= 0)) as boolean;
  }

  get isEditable(): boolean {
    return AppUserModel.getInstance().rights.indexOf(RIGHTS.EDIT_USERS.identifier) >= 0;
  }


  get isRoleEditable(): boolean {
    const teamUserRole: RoleModel | null = RoleListModel.getInstance().getRoleByID(this.teamUser!.roleID);
    const appUserRole: RoleModel | null = RoleListModel.getInstance().getRoleByID(AppUserModel.getInstance().roleID);
    if (teamUserRole && appUserRole) {
      return teamUserRole.level >= appUserRole.level && this.isEditable;
    }
    return false;
  }

  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  mounted() {
    // this._createValidationTeamUser();
    window.addEventListener('beforeunload', this._beforeUnload);
  }

  beforeDestroy() {
    if (this.teamUser && this.teamUser.hasChanges) {
      this._saveUser(this.teamUser);
    }

    window.removeEventListener('beforeunload', this._beforeUnload)
  }

  //---------------------------------
  // Private / helper methods
  //---------------------------------

  private _calculateInputState(inputValidator: any): any {
    return inputValidator.$invalid ? false : null;
  }

  private _beforeUnload(e: BeforeUnloadEvent) {
    if (this.teamUser && this.teamUser.hasChanges) {
      // this._saveUser(this.teamUser);
      e.returnValue = "";
      return e.returnValue;
    }
  }

  private _createValidationTeamUser(p_teamUser: TeamUserModel): void {
    if (this.teamUser) {
      this.validationTeamUser = new TeamUserModel();
      this.validationTeamUser.displayName = p_teamUser.displayName;
    }
  }

  private _onFormValidationInput(inputValidator: any, value: any): void {
    if (!inputValidator.$invalid && this.teamUser) {
      value = inputValidator.$model;
      this._onFormInput(null);
    }
    return value;
  }

  private _onFormInput(p_e: Event | null) {
    this.teamUser!.hasChanges = true;
  }


  private _saveUser(p_teamUser: TeamUserModel) {
    teamUserController.saveTeamUser(p_teamUser);
  }


  private _onOpenAssetPickerBtnClick(p_e: Event) {
    this.$bvModal.show('app-user-asset-picker-box');
  }

  private _onAssetFilePicked(p_assetFile: AssetFileModel) {
    this.$bvModal.hide('app-user-asset-picker-box');
    if (this.isEditable && p_assetFile && this.teamUser) {
      this.teamUser.avatarFileUri = p_assetFile.fileURI;
      this.teamUser.hasChanges = true;
    }
  }

  @Watch('teamUser', {immediate: true, deep: false})
  private _onActiveTeamUserChange(newTeamUser: TeamUserModel | null, oldTeamUser: TeamUserModel | null) {
    if (oldTeamUser && oldTeamUser.hasChanges) {
      this._saveUser(oldTeamUser);
    }
    if (newTeamUser) {
      this._createValidationTeamUser(newTeamUser);
    }
  }

  private async _onDeleteUserBtnClick(p_e: Event) {
    const deleteConfirmed: boolean = await this.$bvModal.msgBoxConfirm(this.$t("SureDeleteUser") as string, {centered: true});

    if (deleteConfirmed) {
      const isDeleted: boolean = await teamUserController.deleteUser(this.teamUser!);
    }
  }

  private async _onImpersonateUserBtnClick(p_e: Event) {
    const token: string | null = await teamUserController.getImpersonationToken(this.teamUser!);
    if (token) {
      const url: string = `?it=${token}&user=${AppUserModel.getInstance().ID}`;
      window.open(url, "_blank")
    }
  }

  private async _onTransferUserBtnClick(p_e: Event) {
    this.$bvModal.show('user-transfer-box');
  }

  private _onUserTransfered() {
    this.$bvModal.hide('user-transfer-box');

  }
}
</script>

