<template>
  <div class="main-sub-page home-page home-page--closed">

    <HomeMenu></HomeMenu>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {RoutingIdentifier} from "@/router";
import AudiencesOverview from "@/audience/_view/AudiencesOverview.vue";
import HomeMenu from "@/_view/menu/HomeMenu.vue";
import {LocalStorageKey} from "@/_model/app.constants";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import IntroductionBox from "@/help/introduction/_view/IntroductionBox.vue";

@Component({
  components: {IntroductionBox, AudiencesOverview, HomeMenu}
})
export default class HomePage extends Vue {

  private routingIdentifier: typeof RoutingIdentifier = RoutingIdentifier;
  private audienceOverviewCollapsed: boolean = true;

  get activeAudience(): AudienceModel | null {
    return AudienceListModel.getInstance().globalSelState.selected;
  }

}
</script>
