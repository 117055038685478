import {ENTITY_CONFIG} from "@/entity/_model/entity.constants";
import EntityListController from "@/entity/_controller/EntityListController";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceController from "@/audience/_controller/AudienceController";
import {IEntityMetaDto} from "@/entity/_model/entity.dto";

class AudienceListController extends EntityListController<AudienceModel> {

    constructor() {
        super(ENTITY_CONFIG.AUDIENCE, AudienceListModel.getInstance(), audienceController)
    }

    public async fetchAllEntities() {
        await super.fetchAllEntities();
        AudienceListModel.getInstance().fullListBodyLoaded = true;
    }

    protected __newEntity(p_dto: IEntityMetaDto): AudienceModel {
        return new AudienceModel(p_dto);
    }

}

//Singleton export
export default new AudienceListController();
