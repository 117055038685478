import ContentModel from "@/content/_model/ContentModel";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import ContentFolderModel from "@/content/_model/ContentFolderModel";
import {DtoType} from "@/_model/app.constants";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {ICreateResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import toastManager, {ToastType} from "@/__libs/toast_manager/ToastManager";
import i18n from "@/__plugins/i18n";


class ContentFolderController {


    //---------------------------------
    // Properties
    //---------------------------------

    private _contentFolderListModel: ContentFolderListModel = ContentFolderListModel.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async getViewableTeams(p_folder: ContentFolderModel): Promise<boolean> {
        const endPoint: string = `/client-api/folders/${p_folder.folderID}`;
        const response: ApiResponse<ContentFolderModel> = await apiManager.sendApiRequest(ApiRequestType.GET, endPoint);

        if (response) {
            p_folder.viewableTeams = (response.result as ContentFolderModel).viewableTeams;
        }
        return response.hasSucceeded;
    }

    public async createContentFolder(p_folder: ContentFolderModel): Promise<boolean> {
        if (this._contentFolderListModel.activeContentFolder.folderID > 0) {
            p_folder.parentFolderID = this._contentFolderListModel.activeContentFolder.folderID;
        }
        const endPoint: string = `/client-api/folders`;
        const response: ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endPoint, p_folder.mapToDto(DtoType.BODY));
        if (response.hasSucceeded) {
            p_folder.isEditable = true;
            p_folder.folderID = (response.result as any).ID;
            this._contentFolderListModel.activeContentFolder.childFolders.push(p_folder);
            this._contentFolderListModel.contentFoldersDict.setItem(p_folder, p_folder.folderID);
        }
        return response.hasSucceeded;
    }


    public async saveContentFolder(p_folder: ContentFolderModel): Promise<boolean> {

        const endPoint: string = `/client-api/folders/${p_folder.folderID}`;
        const response: ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, endPoint, p_folder.mapToDto(DtoType.BODY));
        if (response.hasSucceeded) {
            toastManager.showToast(i18n.t('FolderSaveSuccess') as string, ToastType.SUCCESS);
        } else {
            toastManager.showToast(i18n.t('FolderSaveFailed') as string, ToastType.DANGER);
        }
        return response.hasSucceeded;
    }

    public async deleteFolder(p_folder: ContentFolderModel): Promise<boolean> {
        // this._contentFolderListModel.deleteFolder(p_folder);
        // return true;

        const endPoint: string = `/client-api/folders/${p_folder.folderID}`;
        const response: ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.DELETE, endPoint);

        if (response.hasSucceeded) {
            this._contentFolderListModel.deleteFolder(p_folder);
        }
        return response.hasSucceeded;
    }


    public async moveFolderToFolder(p_movedFolder: ContentFolderModel, p_targetFolder: ContentFolderModel): Promise<boolean> {
        if (p_movedFolder.parentFolderID !== p_targetFolder.folderID) //not trying to move to the same folder
        {
            this._contentFolderListModel.moveFolderToFolder(p_movedFolder, p_targetFolder);
            const endPoint: string = `/client-api/folders/${p_movedFolder.folderID}`;
            const response: ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.PUT, endPoint, p_movedFolder.mapToDto(DtoType.BODY));
            if (response.hasSucceeded) {
                toastManager.showToast(i18n.t('FolderDropInFolderSuccess') as string, ToastType.SUCCESS);
            }
            return response.hasSucceeded;
        }
        return false;


    }

    public async getFolderInfo(p_folder: ContentFolderModel): Promise<boolean> {
        const endPoint: string = `/client-api/folders/${p_folder.folderID}`;
        const response: ApiResponse<ContentFolderModel> = await apiManager.sendApiRequest(ApiRequestType.GET, endPoint);
        if (response.hasSucceeded) {
            p_folder.userName = (response.result as ContentFolderModel).userName;
            p_folder.teamName = (response.result as ContentFolderModel).teamName;
        }
        return response.hasSucceeded;
    }

    //---------------------------------
    // Private Methods
    //---------------------------------


    public startDrag(p_dragged: ContentModel | ContentFolderModel) {
        this._contentFolderListModel.dragInProgress = true;
        this._contentFolderListModel.currentDragged = p_dragged;
    }


    public endDrag() {
        this._contentFolderListModel.dragInProgress = false;
        this._contentFolderListModel.currentDragged = null;
    }


}

//Singleton export
export default new ContentFolderController();
