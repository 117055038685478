<template>
    <div class="data-item-select-list">

        <select v-if="property.propertyDefinition.formElement === componentType.COMBOBOX"
                class="form-control mb-3"
                v-model="property.displayedValue" :disabled="!property.isEditable">
            <option v-for="entry in this.property.propertyDefinition.list" :key="entry.reference"
                    :value="entry.reference">{{getEntryName(entry.name) }}
            </option>
        </select>


        <div v-if="property.propertyDefinition.formElement === componentType.RADIOBUTTON_GROUP">
            <b-form-radio v-for="entry in this.property.propertyDefinition.list" :key="entry.reference"
                          v-model="property.displayedValue" :name="property.propertyDefinition.identifier"
                          :value="entry.reference">
                {{getEntryName(entry.name) }}
            </b-form-radio>
        </div>

    </div>
</template>


<script lang="ts">
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {FormElement} from "@/data_tool/_model/data_tool.constants";
    import PropertyModel from "@/data_tool/data_item/_model/PropertyModel";
    import languageManager, {IMultiLangString} from "@/__libs/language_manager/LanguageManager";
    import AppUserModel from "@/project/user/_model/AppUserModel";


    @Component({
        components: {}
    })
    export default class FixedList extends Vue
    {
        //---------------------------------
        // Vue Component props
        //---------------------------------

        @Prop() private property!:PropertyModel;



        //---------------------------------
        // Vue Component data
        //---------------------------------

        private componentType:typeof FormElement = FormElement;



        //---------------------------------
        // Vue Computed properties
        //---------------------------------


        //---------------------------------
        // Public / lifecycle methods
        //---------------------------------

        public getEntryName(p_name:IMultiLangString)
        {
            return languageManager.getTranslationForValue<string>(p_name, AppUserModel.getInstance().langCode)
        }


        public async created()
        {
            // console.log(this.property.propertyDefinition.identifier, this.property.propertyDefinition);
        }

        public async mounted()
        {

        }



        //---------------------------------
        // Private / helper methods
        //---------------------------------



    }
</script>
