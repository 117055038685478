<template>

    <div class="create-audience">

        <transition name="fade">
            <div v-if="currentFormState === formState.SEARCH_INPUT">
                {{ $t('ImportFrom') }}

                <div class="dmsLogo" v-for="dmsProvider in availableDmsProviders" :key="dmsProvider.provider"
                     @click="_selectProvider(dmsProvider)">
                    <img class="dms__image" width="60px" :src="'img/dms/' + dmsProvider.provider + '.png'"/>
                </div>

                <div>
                    <hr>
                    <div>
                        {{ $t('ListRecentFiles') }}
                        <button class="btn btn-secondary mr-2"  @click="_doSearch(byType.RECENT)">{{
                                $t('Go')
                            }}
                        </button>
                    </div>

                    <strong>{{ $t('Or') }}</strong>

                    <div>
                        {{ $t('SearchByName') }} <input type="text" v-model="searchInput">
                        <button class="btn btn-secondary mr-2" :disabled="searchInput.length < 2"
                                @click="_doSearch(byType.NAME)">{{ $t('Search') }}
                        </button>
                    </div>



                    <hr>

                    <div v-if="isSearching" class="text-center">
                        <b-spinner variant="dark" class="my-5"></b-spinner>
                    </div>
                    <div v-else>
                        <div v-if="this.files.length === 0">
                            {{ $t('NoFilesFound') }}
                        </div>
                        <div v-else>
                            <b-form-checkbox @input="_onSelectAllClick" v-model="allSelected"/>
                            <b-form-checkbox v-for="file in this.files" :key="file.remoteIdentifier"
                                             :value="file.remoteIdentifier" @input="_onFileClick"
                                             v-model="selectedFiles" class="mr-3 mb-1">
                                {{ file.fileName }} <small><i>{{ file.createdByName }}</i></small> <span v-if=" file.isExisting ">({{ $t('FileExists') }})</span>
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>



                <div class="d-flex justify-content-end">
                    <button class="btn btn-secondary mr-2" @click="_onCancelBtnClick">{{ $t('Cancel') }}</button>
                    <button v-if="files.length > 0" class="btn btn-primary" :disabled="selectedFiles.length === 0"
                            @click="_onImportFilesBtnClick">
                        {{ $t('ImportSelectedFiles') }}
                    </button>
                </div>


            </div>
        </transition>


        <transition name="fade">
            <div v-if="currentFormState === formState.IMPORTING_FILES" class="text-center">
                <b-spinner variant="dark" class="my-5"></b-spinner>
                <div>{{ $t('ImportingFiles') }}</div>
                <button class="btn btn-primary" @click="_onCancelBtnClick">
                    {{ $t('Ok') }}
                </button>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="currentFormState === formState.FILES_IMPORTED" class="text-center">
                {{ $t('FilesImported') }}
                <button class="btn btn-primary" @click="_onCancelBtnClick">
                    {{ $t('Ok') }}
                </button>
            </div>
        </transition>


        <transition name="fade">
            <div v-if="currentFormState === formState.IMPORT_ERROR">
                <p class="mb-4 text-danger">{{ $t('AudienceImportFailed') }}</p>

            </div>
        </transition>

    </div>

</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {DmsProvider} from "@/dms/_model/dms.constants";
import DmsModel from "@/dms/_model/DmsModel";
import {IDmsFileDto, IDmsProviderDto, IDmsUserDto} from "@/dms/_model/dms.dto";
import AppUserModel from "@/project/user/_model/AppUserModel";
import DatePicker from "vue2-datepicker";
import dmsController from "@/dms/_controller/DmsController";
import {IDmsProviderClientDto} from "@/dms/_model/dms.dto";

enum FormState
{
    SEARCH_INPUT,
    IMPORTING_FILES,
    FILES_IMPORTED,
    IMPORT_ERROR
}

enum SearchByType
{
    RECENT,
    NAME,
}

@Component({
    components: {DatePicker}
})
export default class ImportContentFilesBox extends Vue
{


    //---------------------------------
    // Validations
    //---------------------------------

    //---------------------------------
    // Vue Component props
    //---------------------------------

    //---------------------------------
    // Vue Component data
    //---------------------------------



    private formState:typeof FormState = FormState;
    private byType:typeof SearchByType = SearchByType;


    private currentFormState:FormState = FormState.SEARCH_INPUT;

    private currentDmsProvider?:IDmsProviderClientDto;

    private fromDate?:Date = new Date();
    private searchInput:string = "";
    private isSearching:boolean = false;
    private latestSearchByType:SearchByType = SearchByType.RECENT;
    private files:IDmsFileDto[] = [];
    private selectedFiles:string[] = [];
    private allSelected:boolean = true;
    private alsoImportFileAccounts:boolean = true;

    //---------------------------------
    // Vue Computed properties
    //---------------------------------



    get availableDmsProviders(){
        if(AppUserModel.getInstance().project.config.dmsProviders )
        {
            return AppUserModel.getInstance().project.config.dmsProviders
        }
        return []
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    mounted()
    {
        this._selectProvider(this.availableDmsProviders![0]);
    }

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private _selectProvider(p_dmsProvider:IDmsProviderClientDto)
    {
        this.currentDmsProvider = p_dmsProvider;
        this.selectedFiles = [];
        this.files = [];


        this._doSearch(this.latestSearchByType);
    }

    private async _doSearch(p_byType:SearchByType)
    {
        this.latestSearchByType = p_byType;
        this.isSearching = true;
        this.files = [];
        if (p_byType === SearchByType.RECENT)
        {
            this.searchInput = "";

            this.files = await dmsController.searchFiles(this.currentDmsProvider!);
        }
        else
        {
            this.fromDate = undefined;
            this.files = await dmsController.searchFiles(this.currentDmsProvider!, this.searchInput);
        }
        this.setSelection(true);
        this.isSearching = false;
    }

    private async _onImportFilesBtnClick(p_e:Event)
    {
        this.currentFormState = FormState.IMPORTING_FILES;
        const hasSucceeded:boolean = await dmsController.doBackendImport(this.currentDmsProvider!, this.selectedFiles, this.alsoImportFileAccounts);
        if (hasSucceeded)
        {
            this.currentFormState = FormState.FILES_IMPORTED;
        }
        else
        {
            this.currentFormState = FormState.IMPORT_ERROR;
        }
    }

    private async _onCancelBtnClick(p_e:Event)
    {
        this.$emit('onFinish', null);
    }

    private setSelection(p_selectAll:boolean = true)
    {
        const selectedFiles:string[] = [];
        if (p_selectAll)
        {
            for (let i = 0; i < this.files.length; i++)
            {
                selectedFiles.push(this.files[i].remoteIdentifier!)
            }
        }
        this.selectedFiles = selectedFiles;
        this.allSelected = p_selectAll;
    }



    private async _onSelectAllClick(p_e:Event)
    {
        this.setSelection(this.allSelected);
    }

    private async _onFileClick(p_e:Event)
    {
        if (this.selectedFiles.length === this.files.length)
        {
            this.allSelected = true;
        }
        else if (this.selectedFiles.length === 0)
        {
            this.allSelected = false;
        }
    }

}
</script>

<style>

.dmsLogo {
    display: inline-block;
}
</style>
