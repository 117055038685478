import {IAudienceSessionDto, IAudienceSessionEventDto} from "@/audience/session/_model/audience_session.dto";
import apiManager, {ApiRequestType, ApiResponse} from "@/_controller/ApiManager";
import {IResultDto, IUpdateResultDto} from "@/entity/_model/entity.dto";
import SyncDb from "@/sync/_model/SyncDb";
import EntityModel from "@/entity/_model/EntityModel";
import AudienceModel from "@/audience/_model/AudienceModel";
import {SessionEnvironment, SessionMode} from "@/audience/session/_model/audience_session.constants";
import {ActivityType} from "@/audience/track/_model/audience_activity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {v4 as uuidv4} from "uuid";
import {GamificationTargetIdentifier} from "@/gamification/_model/gamification.constants";

class AudienceSessionController
{

    //---------------------------------
    // Properties
    //---------------------------------

    private _syncDb:SyncDb = SyncDb.getInstance();


    //---------------------------------
    // Controller Methods
    //---------------------------------

    public async registerAudienceSession(session:IAudienceSessionDto)
    {
        const endpoint:string = `/client-api/audiences/sessions/`;
        const response:ApiResponse<IResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endpoint, session);

        if (!response.hasSucceeded)
        {
            // check if because no internet, then write to waiting room
            console.log("couldn't register session, adding to background sync");
            await this._syncDb.addBackgroundSyncRecord({
                requestType: ApiRequestType.POST, endpoint: endpoint, data: session
            });
        }

    }


    public async registerAudienceSessionEvent(sessionEvent:IAudienceSessionEventDto)
    {
        sessionEvent.clientTimeStamp = new Date();

        const endpoint:string = `/client-api/audiences/sessions/${sessionEvent.parentSessionIdentifier}/events`;

        const response:ApiResponse<IResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, endpoint, sessionEvent);

        if (!response.hasSucceeded)
        {
            // check if because no internet, then write to waiting room
            console.log("couldn't register session event");
            await this._syncDb.addBackgroundSyncRecord({
                requestType: ApiRequestType.POST, endpoint: endpoint, data: sessionEvent
            });

        }
    }

    public  createAudienceSession(p_entity:EntityModel, p_audience:AudienceModel, p_sessionMode:SessionMode, p_activityType:ActivityType, p_subjectIdentifier?:string, p_length?:number, p_parentIdentifier?:string, p_payload?:any, p_gamificationTargetIdentifier?:GamificationTargetIdentifier, p_identifier?:string):string
    {

        const subjectIdentifier:string = p_subjectIdentifier ? p_subjectIdentifier : p_entity.ID;
        const identifier:string = p_identifier ? p_identifier : p_audience.ID + "_" + p_activityType + "_" + subjectIdentifier + "_" + uuidv4();

        const session:IAudienceSessionDto = {
            userID                : AppUserModel.getInstance().ID,
            identifier            : identifier,
            audienceID            : p_audience.ID,
            clientStartedTimeStamp: new Date(),
            sessionEnvironment    : SessionEnvironment.APP,
            sessionMode           : p_sessionMode,
            activityType          : p_activityType,
            subjectIdentifier     : subjectIdentifier
        };
        if(p_length)
        {
            session.length = p_length;
        }
        if(p_parentIdentifier)
        {
            session.parentIdentifier = p_parentIdentifier;
        }
        if(p_payload)
        {
            session.payload = p_payload;
        }
        if(p_gamificationTargetIdentifier)
        {
            session.gamificationTargetIdentifier = p_gamificationTargetIdentifier;
        }
        this.registerAudienceSession(session);
        return session.identifier;
    }

    public async getAudienceSession(p_sessionIdentifier:string):Promise<IAudienceSessionDto | null>
    {
        const endpoint:string = `/client-api/audiences/sessions/` + p_sessionIdentifier;
        const response:ApiResponse<IAudienceSessionDto> = await apiManager.sendApiRequest(ApiRequestType.GET, endpoint);

        if (response.hasSucceeded)
        {
            return response.result as IAudienceSessionDto;
        }

        return null;
    }

    //---------------------------------
    // Private Methods
    //---------------------------------

}

//Singleton export
export default new AudienceSessionController();
