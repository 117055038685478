<template>
  <div class="audience-overview">

    <div class="box box--full-height list-overview">

      <div class="list-overview-filters">

        <b-input-group>
          <template #prepend>
            <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
          </template>
          <b-form-input v-model="selState.searchFilter"
                        :placeholder="$t('AudienceFilterBarSearchPlaceholder')"></b-form-input>
        </b-input-group>

        <div class="filter-button not-available" @click="_orderAudiencesAlphabetClick"
             :class="{ available: fullListBodyLoaded, active: orderedAlphabet }" v-b-tooltip.hover.top :title="$t('ToolTipOrderAlphabet')">
          <i class="fas fa-sort-alpha-down fa-lg"></i>
        </div>

        <div class="filter-button" @click="_toggleMultiSelect"
             :class="{ available: fullListBodyLoaded, active: selState.isMultiSelect }" v-b-tooltip.hover.top :title="$t('ToolTipSelectMultiple')">
          <i class="fas fa-user-friends fa-lg"></i>
        </div>

        <div class="filter-button" @click="_toggleCompany"
             :class="{ available: fullListBodyLoaded, active: isGroupedByCompany }" v-b-tooltip.hover.top :title="$t('ToolTipCompanyView')">
          <i class="fas fa-building fa-lg"></i>
        </div>

      </div>

      <b-button block variant="primary" class="list-overview-button" @click="_onCreateAudienceBtnClick" :id="hintIds.HOME_CREATE_AUDIENCE" v-html="$t('DashboardMasterAddAudienceButton')"></b-button>
      <b-button  v-if="showImportAudiencesBtn" block variant="primary" class="list-overview-button mt-0" @click="_onImportAudiencesBtnClick"  v-html="$t('ImportAudiences')"></b-button>

      <transition name="fade">
        <div class="list-overview-items" v-if="isGroupedByCompany">
          <CompanyGroupItemRenderer
              v-for="company in filteredCompanies"
              :company="company"
              :key="company.ID"
              :selState="selState"/>
        </div>
      </transition>

      <transition name="fade">
        <div class="list-overview-items" v-if="!isGroupedByCompany">
          <AudienceItemRenderer
              v-for="audience in filteredAudiences"
              :audience="audience"
              :key="audience.ID"
              v-on:onClick="_onAudienceClick"
              :selState="selState"
              :item-id="`audience-${audience.ID}`" />
        </div>
      </transition>

    </div>

<!--    <div class="audience-overview__toggle" @click="toggle">-->
<!--      <i class="fas fa-chevron-left" v-if="!collapsed"></i>-->
<!--      <i class="fas fa-chevron-right" v-if="collapsed"></i>-->
<!--    </div>-->

      <b-modal id="create-audience-box" :title="$t('DashboardMasterAddAudienceButton')" hide-footer>
          <CreateAudienceBox v-on:onFinish="_onCreateAudienceFinish"></CreateAudienceBox>
      </b-modal>

      <b-modal v-if="showImportAudiencesBtn" id="import-audiences-box" :title="$t('ImportAudiences')" hide-footer>
          <ImportAudiencesBox v-on:onFinish="_ImportAudiencesFinish"></ImportAudiencesBox>
      </b-modal>

    <b-popover custom-class="hint-popover" :target="hintIds.HOME_CREATE_AUDIENCE"
               placement="right"
               triggers="manual"
               boundary="window">
      <Hint :id="hintIds.HOME_CREATE_AUDIENCE"></Hint>
    </b-popover>

  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AudienceItemRenderer from "@/audience/_view/AudienceItemRenderer.vue";
import AudienceModel from "@/audience/_model/AudienceModel";
import audienceListModel from "@/audience/_model/AudienceListModel";
import audienceListController from "@/audience/_controller/AudienceListController";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions, LocalStorageKey} from "@/_model/app.constants";
import CreateAudienceBox from "@/audience/_view/CreateAudienceBox.vue";
import {RoutingIdentifier} from "@/router";
import {LoadingStatus} from "@/entity/_model/entity.constants";
import companyListController from "@/company/_controller/CompanyListController";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CompanyModel from "@/company/_model/CompanyModel";
import CompanyItemRenderer from "@/company/_view/CompanyItemRenderer.vue";
import CompanyGroupItemRenderer from "@/audience/_view/CompanyGroupItemRenderer.vue";
import JsonUtil from "@/__libs/utility/JsonUtil";
import LocalStorageManager from "@/__libs/offline_storage/LocalStorageManager";
import hintController from "@/help/hint/_controller/HintListController";
import {HintIds} from "@/help/hint/_model/hint.constants";
import Hint from "@/help/hint/_view/Hint.vue";
import ImportAudiencesBox from "@/audience/_view/ImportAudiencesBox.vue";
import {CrmProvider} from "@/crm/_model/crm.constants";
import CrmModel from "@/crm/_model/CrmModel";

@Component({
  components: {
    Hint,
    AudienceItemRenderer, CreateAudienceBox, CompanyGroupItemRenderer, ImportAudiencesBox
  },
})
export default class AudiencesOverview extends Vue {

  @Prop() public collapsed!: boolean;

  private hintIds: typeof HintIds = HintIds;
  private audienceListModel: AudienceListModel = AudienceListModel.getInstance();
  public selState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;
  private audiences: AudienceModel[] = this.audienceListModel.list;
  private companies: CompanyModel[] = CompanyListModel.getInstance().list;
  private orderedAlphabet: boolean = false;
  private isGroupedByCompany: boolean = false;

  get filteredAudiences() {
    if (this.selState.searchFilter.length > 1) {
      return this.audiences.filter(audience => {
        return audience.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1 || this.getCompanyName(audience).toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
      });
    } else {
      return this.audiences;
    }
  }

  get filteredCompanies() {
    // if (this.selState.searchFilter.length > 1)
    // {
    //     return this.companies.filter(company => {
    //         return company.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
    //     });
    // }
    // else
    // {
    //     return this.companies;
    // }
    return this.companies;
  }

  get fullListBodyLoaded(): boolean {
    return this.audienceListModel.fullListBodyLoaded;
  }

    get showImportAudiencesBtn():boolean
    {
        return CrmModel.getInstance().getAvailableCrmProvidersForUser().length > 0;
    }



  //---------------------------------
  // Public / lifecycle methods
  //---------------------------------

  public async mounted() {
    await companyListController.fetchAllEntities();
    audienceListController.fetchAllEntities();

    this.isGroupedByCompany = JsonUtil.parse(LocalStorageManager.retrieveValue(LocalStorageKey.GROUP_BY_COMPANY) as string) ? true : false;

    //
    this._orderAudiencesCreatedDate();
    this._orderCompaniesCreatedDate();
    // on init default select first audience
    if (this.audiences.length > 0 && !this.selState.selected) {
      this.selState.selected = this.audiences[0];
    }
  }

  private async _onAudienceClick(p_audience: AudienceModel, p_e: Event) {
    if (p_audience.loadingStatus === LoadingStatus.BODY_LOADED) {
      this.selState.setSelected(p_audience);
    }
  }

  private getCompanyName(p_audience: AudienceModel) {
    const company: CompanyModel | null = CompanyListModel.getInstance().getEntityByID(p_audience.companyID);
    return company ? company.displayName : "";
  }

  private _onCreateAudienceBtnClick(p_e: Event) {
    this.$bvModal.show('create-audience-box');
  }

  private _onCreateAudienceFinish(p_createdAudience: AudienceModel) {
    this.$bvModal.hide('create-audience-box');
    if (p_createdAudience) {
      this.$router.push({name: RoutingIdentifier.AUDIENCE_DETAIL});
      // order
      if (this.orderedAlphabet) {
        this._orderAudiencesAlphabet();
      } else {
        this._orderAudiencesCreatedDate();
      }
    }
  }

    private _onImportAudiencesBtnClick(p_e: Event) {
        this.$bvModal.show('import-audiences-box');
    }

    private _ImportAudiencesFinish() {
        this.$bvModal.hide('import-audiences-box');
    }

  // ordering

  private _orderAudiencesAlphabetClick() {
    if (this.orderedAlphabet) {
      this._orderAudiencesCreatedDate();
      this._orderCompaniesCreatedDate();
      this.orderedAlphabet = false;
    } else {
      this._orderAudiencesAlphabet();
      this._orderCompaniesAlphabet();
      this.orderedAlphabet = true;
    }
  }

  private _orderAudiencesAlphabet() {
    this.audiences.sort((a: AudienceModel, b: AudienceModel) => {
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
        return -1;
      }
      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  private _orderAudiencesCreatedDate() {
    this.audiences.sort((a: AudienceModel, b: AudienceModel) => {
      let dateA: Date | null = null;
      if (a.createdDate instanceof Date) {
        dateA = a.createdDate;
      } else {
        dateA = new Date(a.createdDate);
      }
      let dateB: Date | null = null;
      if (b.createdDate instanceof Date) {
        dateB = b.createdDate;
      } else {
        dateB = new Date(b.createdDate);
      }
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    });
  }

  private _orderCompaniesAlphabet() {
    this.companies.sort((a: CompanyModel, b: CompanyModel) => {
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
        return -1;
      }
      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  private _orderCompaniesCreatedDate() {
    this.companies.sort((a: CompanyModel, b: CompanyModel) => {
      let dateA: Date | null = null;
      if (a.createdDate instanceof Date) {
        dateA = a.createdDate;
      } else {
        dateA = new Date(a.createdDate);
      }
      let dateB: Date | null = null;
      if (b.createdDate instanceof Date) {
        dateB = b.createdDate;
      } else {
        dateB = new Date(b.createdDate);
      }
      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    });
  }

  private _toggleMultiSelect() {
    this.selState.isMultiSelect = !this.selState.isMultiSelect;
  }

  private _toggleCompany() {
    this.isGroupedByCompany = !this.isGroupedByCompany;
    LocalStorageManager.storeValue(LocalStorageKey.GROUP_BY_COMPANY, JsonUtil.stringify(this.isGroupedByCompany));
  }

  private toggle() {
    this.$emit("toggle");
  }

}
</script>
