<template>
  <div class="base-menu-bar">

<!--    <div class="base-menu">-->
<!--&lt;!&ndash;      <router-link :to="{ name: routingIdentifiers.CONTENT_OVERVIEW }" class="base-menu-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="icon">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="fas fa-book-open fa-2x"></i>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <h6>Present</h6>&ndash;&gt;-->
<!--&lt;!&ndash;      </router-link>&ndash;&gt;-->
<!--      <router-link :to="{ name: routingIdentifiers.SHARE }" class="base-menu-item" :class="{ disabled: !online }" :id="hintIds.HOME_SHARED_CONTENT">-->
<!--        <div class="icon">-->
<!--          <i class="fas fa-share fa-2x"></i>-->
<!--        </div>-->
<!--        <h6 v-html="$t('DashboardTabTitleShared')"></h6>-->
<!--      </router-link>-->
<!--      <router-link :to="{ name: routingIdentifiers.TRACK }" class="base-menu-item" :class="{ disabled: !online }" :id="hintIds.HOME_TRACKING_DATA">-->
<!--        <div class="icon">-->
<!--          <i class="fas fa-chart-simple fa-2x"></i>-->
<!--        </div>-->
<!--        <h6 v-html="$t('DashboardTabTitleTrack')"></h6>-->
<!--      </router-link>-->
<!--&lt;!&ndash;      <router-link :to="{ name: routingIdentifiers.AUDIENCE_DETAIL }" class="base-menu-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="icon">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="fas fa-user fa-2x"></i>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <h6 v-html="$t('DashboardTabTitleAudience')"></h6>&ndash;&gt;-->
<!--&lt;!&ndash;      </router-link>&ndash;&gt;-->
<!--    </div>-->

    <div class="audience-active-item" v-if="audience">
      <AAProfilePicture :name="audience.displayName"
                        :imageUri="audience.avatarFileUri" :big="true"></AAProfilePicture>
      <div class="temp1">
        <small>Selected Audience:</small>
        <h5 class="title">{{ audience.displayName }}</h5>
<!--        <b-button size="sm" @click="changeAudience">change</b-button>-->
        <b-dropdown id="dropdown-1" text="change" variant="secondary">
          <b-dropdown-item @click="changeAudience(theAudience)" v-for="theAudience in audienceListModel.list" :key="theAudience.ID">{{ theAudience.displayName }}</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="temp2">
        <router-link :to="{ name: routingIdentifiers.SHARE }" class="btn btn-primary btn-block">
          <span>Shares</span>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.TRACK }" class="btn btn-primary btn-block">
          <span>Activity</span>
        </router-link>
      </div>
    </div>

    <Highlight v-if="audience"></Highlight>

    <b-popover custom-class="hint-popover" :target="hintIds.HOME_SHARED_CONTENT"
               placement="right"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.HOME_SHARED_CONTENT"></Hint>
    </b-popover>
    <b-popover custom-class="hint-popover" :target="hintIds.HOME_TRACKING_DATA"
               placement="right"
               boundary="window"
               triggers="manual">
      <Hint :id="hintIds.HOME_TRACKING_DATA"></Hint>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {RoutingIdentifier} from "@/router";
import NetworkManager, {NetworkEventType, NetworkState} from "@/_controller/NetworkManager";
import Highlight from "@/_view/components/Highlight.vue";
import Hint from "@/help/hint/_view/Hint.vue";
import {HintIds} from "@/help/hint/_model/hint.constants";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";

@Component({
    components: {AAProfilePicture, Hint, Highlight}
})
export default class HomeMenu extends Vue {

    private hintIds: typeof HintIds = HintIds;
    private routingIdentifiers = RoutingIdentifier;

    public networkManager: NetworkManager = NetworkManager.getInstance();
    private audienceListModel: AudienceListModel = AudienceListModel.getInstance();

    get online() {
        return this.networkManager.online;
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    public changeAudience(audience: AudienceModel) {
        AudienceListModel.getInstance().globalSelState.selected = audience;
    }

}
</script>
