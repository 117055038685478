<template>
  <div class="main-sub-page audiences-page">

    <div class="sub-menu-bar">
      <div class="sub-menu">
        <router-link :to="{ name: routingIdentifiers.AUDIENCE_LIST }" class="sub-menu-item">
          <i class="fas fa-users"></i>
          <h6>Audiences</h6>
        </router-link>
        <router-link :to="{ name: routingIdentifiers.COMPANIES }" v-if="showCompanies"  class="sub-menu-item">
          <i class="fas fa-building"></i>
          <h6 v-html="$t('Companies')"></h6>
        </router-link>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import AudienceSummary from "@/audience/_view/AudienceSummary.vue";
import AudienceDetail from "@/audience/_view/AudienceDetail.vue";
import AudiencesOverview from "@/audience/_view/AudiencesOverview.vue";
import {RoutingIdentifier} from "@/router";
import AppUserModel from "@/project/user/_model/AppUserModel";
import {RIGHTS} from "@/team/_model/role.constants";

@Component({
  components: {AudiencesOverview, AudienceDetail, AudienceSummary}
})
export default class AudiencesPage extends Vue {

    public routingIdentifiers = RoutingIdentifier;

    get showCompanies(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.VIEW_COMPANIES.identifier) >= 0;
    }

}
</script>
