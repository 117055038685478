<template>
  <div class="company-group-item" :class="{ active: isSelected }">

    <div class="list-item company-group-company-item" :class="{ 'body-loaded': bodyLoaded, 'clickable': this.selState.isMultiSelect }"
         v-on:click="_onClick">

      <div class="list-item__multi-select" v-if="this.selState.isMultiSelect">
        <i class="far fa-circle" v-if="hasNoSelected"></i>
        <i class="fas fa-check-circle" v-if="hasAllSelected"></i>
        <i class="far fa-check-circle" v-if="hasSomeSelected"></i>
      </div>

      <div class="list-item__icon">
        <AAProfilePicture :name="company.displayName" :imageUri="company.companyLogo"></AAProfilePicture>
        <div class="list-item__icon__badges">
          <span class="badge badge-success" v-if="isNew">{{ $t('ContentItemDeltaNew') }}</span>
          <span class="badge badge-info" v-if="isUpdated">{{ $t('ContentItemDeltaUpdated') }}</span>
          <span class="badge badge-danger" v-if="isDeleted">{{ $t('ContentItemDeltaDeleted') }}</span>
        </div>
      </div>

      <div class="list-item__info">
        <div class="list-item__info__skeleton" v-if="!bodyLoaded">
          <div class="bar"></div>
        </div>
        <h5 class="list-item__info__primary mb-0" v-line-clamp="1" v-if="bodyLoaded">{{ company.displayName }}</h5>
      </div>
    </div>

    <div class="company-group-item__audiences" v-if="filteredAudiences.length > 0">
      <AudienceItemRenderer
          v-for="audience in filteredAudiences"
          :audience="audience"
          :key="audience.ID"
          v-on:onClick="_onAudienceClick"
          :selState="selState"
          :item-id="`company-audience-${audience.ID}`"/>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

import AudienceModel from "@/audience/_model/AudienceModel";
import {DeltaStatus, LoadingStatus} from "@/entity/_model/entity.constants";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import MorePopup from "@/_view/components/MorePopup.vue";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import CompanyListModel from "@/company/_model/CompanyListModel";
import CompanyModel from "@/company/_model/CompanyModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import AudienceItemRenderer from "@/audience/_view/AudienceItemRenderer.vue";

enum GroupSelectState {
  NONE = "NONE",
  SOME = "SOME",
  ALL = "ALL"
}

@Component({
  components: {
    AAProfilePicture, MorePopup, EntityActionBox, AudienceItemRenderer
  }
})
export default class CompanyGroupItemRenderer extends Vue {

  @Prop() public company!: CompanyModel;
  @Prop() private selState!: EntitySelectionState<AudienceModel>;

  private isGroupSelected: boolean = false; //todo: no more needed, unless percentage is not good enough

  private groupSelectState: typeof GroupSelectState = GroupSelectState;
  private currentGroupSelectState: GroupSelectState = GroupSelectState.NONE;

  private selectedPercentage: number = 0;

  get filteredAudiences() {
    if (this.selState.searchFilter.length > 1) {
      return this.audiences.filter(audience => {
        return audience.displayName.toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1 || this.getCompanyName(audience).toLowerCase().indexOf(this.selState.searchFilter.toLowerCase()) > -1
      });
    } else {
      return this.audiences;
    }
  }

  get isSelected() {
    return this.selState.selected && this.selState.selected.companyID === this.company.ID;
  }

  get audiences() {
    return AudienceListModel.getInstance().getAudiencesForCompany(this.company.ID);
  }

  get selecteds() {
    return this.selState.selecteds;
  }

  get bodyLoaded() {
    return this.company.loadingStatus >= LoadingStatus.BODY_LOADED;
  }

  get hasNoSelected() {
    return this.currentGroupSelectState === GroupSelectState.NONE;
  }

  get hasAllSelected() {
    return this.currentGroupSelectState === GroupSelectState.ALL;
  }

  get hasSomeSelected() {
    return this.currentGroupSelectState === GroupSelectState.SOME;
  }

  get isNew() {
    return this.company.deltaStatus == DeltaStatus.NEW;
  }

  get isUpdated() {
    return this.company.deltaStatus == DeltaStatus.UPDATED;
  }

  get isDeleted() {
    return this.company.deltaStatus == DeltaStatus.DELETED;
  }

  public mounted() {
    this.setGroupSelectState();
  }

  public _onClick(p_e: Event) {
    if (this.bodyLoaded && this.selState.isMultiSelect) {
      this.isGroupSelected = !this.isGroupSelected;
      for (let i = 0; i < this.audiences.length; i++) {
        const audience = this.audiences[i];

        //if less than half are selected, select them all?
        if (this.selectedPercentage < 50) {
          this.selState.addToSelecteds(audience);
        } else //otherwise, deselect them all
        {
          this.selState.removeFromSelecteds(audience);
        }
      }
    }
  }

  private getCompanyName(p_audience: AudienceModel) {
    const company: CompanyModel | null = CompanyListModel.getInstance().getEntityByID(p_audience.companyID);
    return company ? company.displayName : "";
  }

  private async _onAudienceClick(p_audience: AudienceModel, p_e: Event) {
    if (p_audience.loadingStatus === LoadingStatus.BODY_LOADED) {
      this.selState.setSelected(p_audience);
    }
  }

  @Watch('selecteds', {immediate: true, deep: false})
  private _onActiveAudienceChange() {
    this.setGroupSelectState();
  }

  //check if all audiences in a group are selected or deselected, so you can toggle "currentGroupSelectState" accordingly
  private setGroupSelectState() {
    let nrOfSelectsInThisGroup: number = 0;
    for (let i = 0; i < this.audiences.length; i++) {
      const audience = this.audiences[i];

      if (this.selState.checkSelected(audience)) {
        nrOfSelectsInThisGroup++;
      }
    }
    this.selectedPercentage = Math.round(nrOfSelectsInThisGroup / this.audiences.length * 100);

    this.isGroupSelected = false;

    if (this.selectedPercentage === 0) {
      this.currentGroupSelectState = GroupSelectState.NONE
    } else if (this.selectedPercentage === 100) {
      this.isGroupSelected = true;
      this.currentGroupSelectState = GroupSelectState.ALL;
    } else {
      this.currentGroupSelectState = GroupSelectState.SOME;
    }
  }

}
</script>
