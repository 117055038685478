<template>
  <div class="entity-action-box text-center">

    <div v-if="nothingAllowed" class="placeholder-text text-secondary text-center px-4 pb-2">
      {{ $t('MissingRightTitle') }}
    </div>

    <div v-if="showPresent || showShareWithAudience || showPrepare" class="mb-2">

      <transition name="fade">
        <div v-if="!audience && audienceAmount > 0" class="no-audience-selected mt-2 mb-3">
          <i class="bulb fa-solid fa-circle-exclamation fa-2x"></i>
          <div class="mb-3">{{ $t('EntityActionBoxAudienceSelect') }}</div>
          <img src="img/no-audience.png">
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!audience && audienceAmount === 0" class="no-audience-selected mt-2 mb-3">
          <i class="bulb fa-solid fa-circle-exclamation fa-2x"></i>
          <div class="mb-3">{{ $t('EntityActionBoxAudienceCreate') }}</div>
          <img src="img/no-audience.png">
        </div>
      </transition>

      <transition name="fade">
        <div v-if="audience">

          <AAProfilePicture :name="audience.displayName"
                            :imageUri="audience.avatarFileUri" :big="true"></AAProfilePicture>

          <h6 class="title mb-4" v-line-clamp="2">{{ audience.displayName }}</h6>

          <b-button v-if="showPresent && isAudienceOnline" :disabled="!hasJoinedRoom" block variant="success"
                    @click="_onPresentContentBtnClick">
            <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxPresentRemote') }}
          </b-button>
          <template v-else>
            <b-button v-if="showPresent" block variant="success" @click="_onPresentContentBtnClick">
              <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxPresent') }}
            </b-button>
          </template>


          <b-button v-if="isAudienceOnline && !hasJoinedRoom" block variant="success" @click="_onConnectBtnClick">
            <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxConnect') }}
          </b-button>

          <b-button v-if="showPrepare" block variant="primary"
                    @click="_onPrepareContentBtnClick" :disabled="!online">
            <i class="fas fa-edit fa-lg"></i>{{ $t('EntityActionBoxPrepare') }}
          </b-button>

          <b-button v-if="showTrain" block variant="primary"
                    @click="_onTrainContentBtnClick">
            <i class="fas fa-bicycle fa-lg"></i>{{ $t('EntityActionBoxTrain') }}
          </b-button>

          <b-button v-if="showShareWithAudience" block variant="primary"
                    @click="_onShareWithAudienceBtnClick" :disabled="!online">
            <i class="fas fa-external-link-alt fa-lg"></i>{{ $t('EntityActionBoxShare') }}
          </b-button>
        </div>
      </transition>

    </div>

    <b-button v-if="showConnect" block variant="success" @click="_onConnectBtnClick">
      <i class="fas fa-chalkboard-teacher fa-lg"></i>{{ $t('EntityActionBoxConnect') }}
    </b-button>

    <b-button v-if="showShareWithMultipleAudiences" block variant="outline-secondary"
              @click="_onShareWithAudiencesBtnClick" :disabled="!online">
      <i class="fas fa-external-link-alt fa-lg"></i>{{ $t('ShareWithSelectedAudiences') }}
    </b-button>

    <h6 class="text-left">For every Audience:</h6>

    <b-button v-if="showEdit" block variant="outline-secondary" @click="_onEditContentBtnClick" :disabled="!online">
      <i class="fas fa-pen-to-square fa-lg"></i>{{ $t('DashboardShowEditButton') }}
    </b-button>

    <b-button v-if="showCopy" block variant="outline-secondary" @click="_onCopyEntityBtnClick" :disabled="!online">
      <i class="fas fa-clone fa-lg"></i>{{ $t('DashboardShowDuplicateButton') }}
    </b-button>

    <b-button v-if="showDelete" block variant="outline-secondary" @click="_onDeleteEntityBtnClick" :disabled="!online">
      <i class="fas fa-trash-can fa-lg"></i>{{ $t('DashboardShowDeleteButton') }}
    </b-button>

    <b-button v-if="showShareWithTeams" block variant="outline-secondary" @click="_onShareWithTeamsBtnClick"
              :disabled="!online">
      <i class="fas fa-people-group fa-lg"></i>{{ $t('DashboardShowShareWithTeamButton') }}
    </b-button>

    <b-button v-if="showTranslate" block variant="outline-secondary" @click="_onTranslateBtnClick">
      <i class="fas fa-earth-americas fa-lg"></i>{{ $t('Translate') }}
    </b-button>

    <b-button v-if="showInfo" block variant="outline-secondary" @click="_onInfoBtnClick">
      <i class="fas fa-circle-info fa-lg"></i>{{ $t('Info') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import {EventBus} from "@/__libs/vue/EventBus";
import {EventBusActions} from "@/_model/app.constants";
import {RightAction, RIGHTS} from "@/team/_model/role.constants";
import EntityModel from "@/entity/_model/EntityModel";
import {ENTITY_CONFIG, EntityType, IEntityTypeConfig} from "@/entity/_model/entity.constants";
import AppUserModel from "@/project/user/_model/AppUserModel";
import ContentModel from "@/content/_model/ContentModel";
import ContentListModel from "@/content/_model/ContentListModel";
import AAProfilePicture from "@/_view/components/AAProfilePicture.vue";
import ContentAppModel from "@/content_app/_model/ContentAppModel";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import NetworkManager from "@/_controller/NetworkManager";
import {
    CobrowseUserType
} from "@sales-drive/audience-library/lib/cobrowse_manager/cobrowse_user/_model/cobrowse_user.constants";
import CobrowseManager from "@sales-drive/audience-library/lib/cobrowse_manager/CobrowseManager";
import {CobrowseRoomType} from "@sales-drive/audience-library/lib/cobrowse_manager/room/_model/room.constants";
import appUserController from "@/project/user/_controller/AppUserController";

@Component({
    components: {
        AAProfilePicture
    }
})
export default class EntityActionBox extends Vue {

    public networkManager: NetworkManager = NetworkManager.getInstance();
    public cobrowseManager: CobrowseManager = CobrowseManager.getInstance();
    public appUserModel: AppUserModel = AppUserModel.getInstance();

    @Prop() public entity!: EntityModel;
    @Prop() public editAllowed!: boolean;

    get online() {
        return this.networkManager.online;
    }

    get audience(): AudienceModel | null {
        return AudienceListModel.getInstance().globalSelState.selected;
    }

    get audienceAmount(): number {
        return AudienceListModel.getInstance().list.length;
    }

    get content(): ContentModel | null {
        return ContentListModel.getInstance().globalSelState.selected;
    }

    get nothingAllowed(): boolean {
        return !this.showPresent && !this.showEdit && !this.showCopy && !this.showDelete && !this.showShareWithAudience && !this.showShareWithTeams && !this.showPrepare && !this.showInfo;
    }

    get entityConfig(): IEntityTypeConfig {
        return ENTITY_CONFIG[this.entity.entityType];
    }

    get showPresent(): boolean {
        const showPresent: boolean = this.entityConfig.canBePresented && this.entity.rightActions.indexOf(RightAction.PRESENT) >= 0;
        if (this.entity.entityType === EntityType.CONTENT_APP) {
            return showPresent && (this.entity as ContentAppModel).canBePresented;
        }
        return showPresent;
    }

    get showPrepare(): boolean {
        const showPrepare: boolean = this.entityConfig.canBePrepared;
        if (this.entity.entityType === EntityType.CONTENT_APP) {
            return showPrepare && (this.entity as ContentAppModel).canBePrepared;
        }
        return showPrepare;
        //&&                this.entity.rightActions.indexOf(
        // RightAction.PREPARE) >= 0;
    }

    get showTrain(): boolean {
        const showTrain: boolean = this.entityConfig.canBeTrained;
        if (this.entity.entityType === EntityType.CONTENT_APP) {
            return showTrain && (this.entity as ContentAppModel).canBeTrained;
        }
        return showTrain;
    }

    get showEdit(): boolean {
        const showEdit: boolean = this.entity.rightActions.indexOf(RightAction.EDIT) >= 0 && this.editAllowed;
        if (this.entity.entityType === EntityType.CONTENT_APP) {
            return showEdit && (this.entity as ContentAppModel).canBeEdited;
        }
        return showEdit;
    }

    get showCopy(): boolean {
        const showCopy: boolean = this.entityConfig.canBeCopied && this.entity.rightActions.indexOf(RightAction.COPY) >= 0;
        if (this.entity.entityType === EntityType.CONTENT_APP) {
            return showCopy && (this.entity as ContentAppModel).canBeCopied;
        }
        return showCopy;
    }

    get showDelete(): boolean {
        return this.entity.rightActions.indexOf(RightAction.DELETE) >= 0;
    }

    get showShareWithAudience(): boolean {
        const showShareWithAudience: boolean = AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_AUDIENCES.identifier) >= 0 &&
          this.entityConfig.canBeSharedWithAudiences &&
          this.entity.rightActions.indexOf(RightAction.SHARE_WITH_AUDIENCES) >= 0;
        if (this.entity.entityType === EntityType.CONTENT_APP) {
            return showShareWithAudience && (this.entity as ContentAppModel).canBeSharedWithAudiences;
        }
        return showShareWithAudience;
    }

    get showShareWithTeams(): boolean {
        return AppUserModel.getInstance().rights.indexOf(RIGHTS.SHARE_ENTITIES_WITH_TEAMS.identifier) >= 0 &&
          this.entity.rightActions.indexOf(RightAction.SHARE_WITH_TEAMS) >= 0;
    }

    get showShareWithMultipleAudiences(): boolean {
        const globalSelState: EntitySelectionState<AudienceModel> = AudienceListModel.getInstance().globalSelState;
        const shareWithMultiple: boolean = globalSelState.hasMultipleSelected && globalSelState.selecteds.length > 0;
        return this.showShareWithAudience && shareWithMultiple;
    }

    get showInfo(): boolean {
        return this.entityConfig.hasInfo;
    }

    get showTranslate(): boolean {
        return this.entityConfig.canBeTranslated && AppUserModel.getInstance().rights.indexOf(RIGHTS.TRANSLATE_CONTENT.identifier) >= 0;
    }

    get showConnect(): boolean {
        if (this.entity.entityType === EntityType.AUDIENCE && this.audience && this.isAudienceOnline && !this.hasJoinedRoom) {
            return true
        }
        return false;
    }


    get isAudienceOnline(): boolean {
        if (this.cobrowseManager.projectRoom && this.audience) {
            const users = this.cobrowseManager.projectRoom!.roomUsersSO!.data.users;
            return users.findIndex((user) => user.cobrowseUserID === `${CobrowseUserType.AUDIENCE}-${this.audience!.ID}`) > -1;
        }
        return false;
    }

    get hasJoinedRoom(): boolean {
        if (this.audience) {
            const roomIdentifier: string = `${this.appUserModel.project.identifier}:${CobrowseRoomType.PORTAL}:${this.audience?.ID}`;
            return this.cobrowseManager.rooms.indexOf(roomIdentifier) >= 0;
        }
        return false;
    }

    //---------------------------------
    // Public / lifecycle methods
    //---------------------------------

    //---------------------------------
    // Private / helper methods
    //---------------------------------

    private async _onPresentContentBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_PRESENT_CONTENT, this.entity);
    }

    private _onPrepareContentBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_PREPARE_CONTENT, this.entity);
    }

    private _onTrainContentBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_TRAIN_CONTENT, this.entity);
    }

    private _onEditContentBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_EDIT_ENTITY, this.entity);
    }

    private _onDeleteEntityBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_DELETE_ENTITY, this.entity);
    }

    private _onCopyEntityBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_COPY_ENTITY, this.entity);
    }

    private _onShareWithAudienceBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_SHARE_CONTENT_WITH_AUDIENCE, this.entity);
    }


    private _onShareWithAudiencesBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_SHARE_CONTENT_WITH_AUDIENCES, this.entity);
    }

    private _onShareWithTeamsBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_SHARE_ENTITY_WITH_TEAMS, this.entity);
    }

    private _onInfoBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_SHOW_ENTITY_INFO, this.entity);
    }

    private _onConnectBtnClick(p_e: Event) {
        appUserController.joinPortalRoom(this.audience!.ID);
    }

    private _onTranslateBtnClick(p_e: Event) {
        EventBus.$emit(EventBusActions.ASK_SHOW_ENTITY_TRANSLATION, this.entity);
    }

}
</script>
