<template>
  <div class="d-none"></div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ToastManager, {ToastEventType} from "@/__libs/toast_manager/ToastManager";

@Component({
    components: {}
})
export default class ToastHolder extends Vue {

    public mounted() {
        ToastManager.addListener(ToastEventType.SHOW, (event) => {
            this.$bvToast.toast(event.text, {
                variant: event.type,
                toaster: 'b-toaster-bottom-center',
                solid: true,
            })
        });
    }

}
</script>


