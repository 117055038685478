import {
    IDataItemDefinitionDto,
    IPropertyDefinitionDto,
    IXlsColumn,
    IXlsImportResult
} from "@/data_tool/_model/data_tool.dto";
import {DataModel} from "@/data_tool/_model/DataModel";
import DataProviderModel from "@/data_tool/data_provider/_model/DataProviderModel";
import {DtoType} from "@/_model/app.constants";
import DataItemModel from "@/data_tool/data_item/_model/DataItemModel";
import Dictionary from "@/__libs/_model/Dictionary";

export default class DataItemProviderLinkModel extends DataModel
{
    public dataSchemaURI:string; //the schema the linked dataprovider needs to confirm to

    protected includeInLogPath:boolean = false;

    private _dataProviderURI:string | null = null; // the linked dataprovider
    get dataProviderURI():string | null
    {
        return this._dataProviderURI;
    }

    set dataProviderURI(value:string | null)
    {
        if (value !== this._dataProviderURI)
        {
            this._dataProviderURI = value;
            //trigger validation
            if (this.__parent)
            {
                this.invalidChildren = [];
                this.__parent.childValidityChange(true, this);
                this.__parent.validate();
            }
            this.dataProvider.hasChanges = true;
        }
    }


    //---------------------------------
    // Constructor
    //---------------------------------

    constructor(p_dataSchemaURI:string, p_data:any, p_dataProvider:DataProviderModel, p_parent:DataModel | null)
    {
        super(p_dataProvider, p_parent);
        this.dataSchemaURI = p_dataSchemaURI;
        this.build(p_data);
    }

    //---------------------------------
    // Public Methods
    //---------------------------------

    public build(p_data:any)
    {
        if (p_data && p_data.dataProviderURI)
        {
            this._dataProviderURI = p_data.dataProviderURI;
        }
    }


    public mapToDto(p_dtoType:DtoType):any
    {
        if (p_dtoType === DtoType.BODY)
        {
            return {
                dataProviderURI: this._dataProviderURI,
            }
        }
    }

    public mapToRows(p_rows:any, p_columnPrefix:string)
    {
        //todo
        // const lastRow:any = p_rows[p_rows.length - 1];
        // lastRow[`${p_columnPrefix}:chosen list`] = this._dataProviderURI;
    }


    public static mapFromRow(p_propDefinition:IPropertyDefinitionDto, p_row:any, p_columns:IXlsColumn[], p_result:IXlsImportResult)
    {
        const dto:any = {};
        const dataProviderURIColumn:IXlsColumn = p_columns[0]; //todo: safe to presume this is the first or give it type when building columns?

        dto.dataProviderURI = p_row[dataProviderURIColumn.identifier]; // todo validate

        return dto;
    }

}
